import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
// Matarial
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
// Icons
import PublishIcon from '@mui/icons-material/Publish';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
// Util
import { resizeFile } from '../../functions';

const Input = styled('input')({
    display: 'none',
});

const AddEquipment = ({ id }) => {

    const [load, setLoad] = useState(false)

    let formData = new FormData();
    const navigate = useNavigate()


    const [category, setCategory] = useState('')
    const selectCategory = (e) => {
        setCategory(e.target.value)
        loadSubCategory(e.target.value)
    }
    const [subCategory, setSubCategory] = useState('')
    const selectSubCategory = (e) => {
        setSubCategory(e.target.value)
    }
    const [title, setTitle] = useState("")
    const [condition, setCondition] = useState('')
    const [description, setDescription] = useState('')
    const [yearModel, setYearModel] = useState('')
    const [price, setPrice] = useState('')
    const [place, setPlace] = useState('')
    const [make, setMake] = useState('')
    const [model, setModel] = useState('')
    const [totalWeight, setTotalWeight] = useState('')
    const [loadWeight, setLoadWeight] = useState('')

    const [categoryList, setCategoryList] = useState([])
    const [subCategoryList, setSubCategoryList] = useState([])
    const [ageList, setAgeList] = useState([])

    const [imageUrl, setImageUrl] = useState([])
    const [image, setImage] = useState([])

    const [primaryImageUrl, setPrimaryImageUrl] = useState(null)
    const [primaryImage, setPrimaryImage] = useState([])

    const [termsAccept, setTermsAccept] = useState(false)
    const [policyAccept, setPolicyAccept] = useState(false)

    useEffect(() => {
        setLoad(false)
        loadCategory()

        const currentYear = (new Date()).getFullYear();
        const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + (i * step));
        setAgeList(range(currentYear, currentYear - 50, -1));


        setLoad(true)
    }, [])

    // Load Data functions


    const loadCategory = () => {
        axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/category/api_get_sub_category.php`, {
            params: {
                id: 19
            },
            headers: {
                'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
            }
        }).then((response) => {
            setCategoryList(response.data)
        })
    }

    const loadSubCategory = (id) => {
        axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/category/api_get_sub_sub_category.php`, {
            params: {
                id: 19,
                sid: id
            },
            headers: {
                'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
            }
        }).then((response) => {
            setSubCategoryList(response.data)
        })
    }

    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState("");

    const add = () => {
        setLoading(true)
        setErrorMessage("")

        if (!category) {
            setLoading(false)
            setErrorMessage("Ange kategori tack")
            return
        }
        if (!title) {
            setLoading(false)
            setErrorMessage("Fyll titel tack")
            return
        }

        if (!price) {
            setLoading(false)
            setErrorMessage("Fyll pris tack")
            return
        }
        if (!place) {
            setLoading(false)
            setErrorMessage("Fyll plats tack")
            return
        }
        if (!policyAccept) {
            setLoading(false)
            setErrorMessage("Acceptera personuppgiftspolicy tack")
            return
        }
        if (!termsAccept) {
            setLoading(false)
            setErrorMessage("Acceptera vilkor tack")
            return
        }

        formData.append('title', title)
        formData.append('price', price)
        formData.append('user_ID', id)
        formData.append('description', description)
        formData.append('cat', 'equipment')


        formData.append('category', category)
        formData.append('subCategory', subCategory)
        formData.append('condition', condition)
        formData.append('make', make)
        formData.append('model', model)
        formData.append('totalWeight', totalWeight)
        formData.append('loadWeight', loadWeight)
        formData.append('yearModel', yearModel)
        formData.append('place', place)


        for (let i = 0; i < image.length; i++) {
            formData.append("images[]", image[i]);
        }

        for (let i = 0; i < primaryImage.length; i++) {
            formData.append("primaryImage[]", primaryImage[i]);
        }
        formData.append('primaryImage[]', primaryImage)

        axios({
            method: 'POST',
            url: `${process.env.REACT_APP_SERVER_LINK}/api/product/api-post-product-equipment.php`,
            data: formData,
            headers: { 'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}` }
        }).then(function (response) {
            // navigate("/account/announce")
            navigate("/confirmation")
        }).catch(function (response) {
            console.log(response);
        });
    }


    const addImage = (e) => {
        const [file] = e.target.files;
        if (file) {
            for (let i = 0; i < e.target.files.length; i++) {
                resizeFile(e.target.files[i], 1024, (resizedImage) => {
                    setImage(image => [...image, resizedImage])
                    if (file && file.type.startsWith('image/')) {
                        setImageUrl(imageUrl => [...imageUrl, URL.createObjectURL(e.target.files[i])]);
                    } else {
                        console.log("error")
                    }
                });
            }
        }
    }


    const addPrimaryImage = (e) => {
        const [file] = e.target.files;
        if (file) {
            resizeFile(file, 1024, (resizedImage) => {
                setPrimaryImage(resizedImage)
                if (file && file.type.startsWith('image/')) {
                    setPrimaryImageUrl(URL.createObjectURL(file));
                } else {
                    console.log("error")
                }
            });
        }
    }



    const deletePrimaryImg = () => {
        setPrimaryImage(primaryImage => [])
        setPrimaryImageUrl(primaryImageUrl => []);
    }

    const deleteImg = (index) => {
        setImage(image => image.filter((img, i) => i !== index))
        setImageUrl(imageUrl => imageUrl.filter((img, i) => i !== index))
    }

    return (
        <section>
            <h1>Lägg till en annons (Utrustning)</h1>
            {load ?
                <div>
                    <div className='adding-container'>
                        <div className='primary-image-container'>
                            <div className="primary-image-container">
                                <div className='images-container-primary-image-content'>
                                    {primaryImageUrl ?
                                        <div  className='primary-images-container-imgs' onClick={() => deletePrimaryImg()}>
                                            <img src={primaryImageUrl} alt="img" />
                                            <div className='primary-image-edit-view'>
                                                <CloseIcon fontSize="inherit" />
                                            </div>
                                        </div>
                                        :
                                        <label htmlFor="icon-button-files">
                                            <div className='add-img-container'>
                                                <Input accept="image/*" id="icon-button-files" type="file" onChange={addPrimaryImage} />
                                            </div>
                                            <div className='primary-image-container-none'>
                                                <p>Lägg till primär bild</p>
                                                <div className='primary-image-container-none-icon'>
                                                    <AddPhotoAlternateIcon />
                                                </div>
                                            </div>
                                        </label>

                                    }
                                </div>
                            </div>
                        </div>
                        <div className="images-container">

                            {imageUrl.length > 0 ?
                                <div className='images-container-imgs-content'>
                                    {imageUrl.map((val, i) => {
                                        return (
                                            <div key={i} className='images-container-imgs'>
                                                <img src={val} alt="img" />
                                                <div className='delete-image'>
                                                    <IconButton aria-label="delete" size="small" onClick={() => deleteImg(i)}>
                                                        <CloseIcon fontSize="inherit" />
                                                    </IconButton>
                                                </div>
                                            </div>
                                        )
                                    })}
                                    <div className='images-container-add-more-img'>
                                        <IconButton color="primary" aria-label="upload picture" component="label">
                                            <input hidden accept="image/*" type="file" onChange={addImage} />
                                            <AddIcon />
                                        </IconButton>
                                    </div>
                                </div>
                                :
                                <label htmlFor="icon-button-file">
                                    <div className='add-img-container'>
                                        <Input accept="image/*" id="icon-button-file" multiple type="file" onChange={addImage} />
                                    </div>
                                    <div className='images-container-none'>
                                        <p>lägg till dina filer (bilder)</p>
                                        <div className='images-container-none-icon'>
                                            <AddPhotoAlternateIcon />
                                        </div>
                                    </div>
                                </label>
                            }
                        </div>
                        <FormControl required>
                            <InputLabel >Kategori</InputLabel>
                            <Select
                                value={category}
                                label="Kategori"
                                onChange={(e) => selectCategory(e)}
                            >
                                {categoryList.map((val, i) => {
                                    return <MenuItem key={i} value={val.sub_category_ID}>{val.name}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                        {subCategoryList.length > 0 ?
                            <FormControl >
                                <InputLabel >Underkategori</InputLabel>
                                <Select
                                    value={subCategory}
                                    label="Underkategori"
                                    onChange={(e) => selectSubCategory(e)}
                                >
                                    {subCategoryList.map((val, i) => {
                                        return <MenuItem key={i} value={val.sub_sub_category_ID}>{val.name}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                            : null}
                    </div>
                    <div className='adding-container'>
                        <TextField label="Title" required variant="outlined" onChange={(e) => setTitle(e.target.value)} />
                        <FormControl >
                            <InputLabel >Skick</InputLabel>
                            <Select
                                value={condition}
                                label="Skick"
                                onChange={(e) => setCondition(e.target.value)}
                            >
                                <MenuItem value={0}>Oanvänd</MenuItem>
                                <MenuItem value={1}>Nyskick</MenuItem>
                                <MenuItem value={2}>Begagnad</MenuItem>
                                <MenuItem value={3}>Sliten</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField label="Märke" variant="outlined" onChange={(e) => setMake(e.target.value)} />
                        <TextField label="Modell" variant="outlined" onChange={(e) => setModel(e.target.value)} />
                        <TextField label="Totalvikt (kg)" variant="outlined" onChange={(e) => setTotalWeight(e.target.value)} />
                        <TextField label="Lastvikt (kg)" variant="outlined" onChange={(e) => setLoadWeight(e.target.value)} />
                        <FormControl >
                            <InputLabel >Årsmodell</InputLabel>
                            <Select
                                value={yearModel}
                                label="Årsmodell"
                                onChange={(e) => setYearModel(e.target.value)}
                            >
                                {ageList.map((val, i) => {
                                    return <MenuItem key={i} value={val}>{val}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                        <TextField label="Pris (SEK)" required type="number" variant="outlined" onChange={(e) => setPrice(e.target.value)} />
                        <TextField label="Plats" required variant="outlined" onChange={(e) => setPlace(e.target.value)} />

                        <TextField
                            className='description-field'
                            label="Beskrivning"
                            multiline
                            rows={4}
                            onChange={(e) => setDescription(e.target.value)}
                        />

                        <FormGroup style={{ width: '100%' }}>
                            <FormControlLabel control={<Checkbox />} label={<p>Jag har läst och godkänner <Link target="_blank" rel="noopener noreferrer" to="/personal-data-policy" style={{ color: '#1D7ABF' }}>personuppgiftspolicy</Link></p>} onChange={(e) => setPolicyAccept(e.target.checked)} />
                            <FormControlLabel control={<Checkbox />} label={<p>Jag har läst och godkänner <Link target="_blank" rel="noopener noreferrer" to="/terms-of-use" style={{ color: '#1D7ABF' }}>användarvillkor</Link></p>} onChange={(e) => setTermsAccept(e.target.checked)} />
                        </FormGroup>



                        <LoadingButton
                            loading={loading}
                            type="submit"
                            onClick={add} variant="contained" color="secondary" sx={{ mt: 2 }}
                            endIcon={<PublishIcon />}
                        >
                            Publicera
                        </LoadingButton>

                        {errorMessage ? <Alert variant="filled" severity="error"> {errorMessage} </Alert> : null}

                    </div>
                </div>
                :
                <Box sx={{ width: '100%' }}>
                    <LinearProgress />
                </Box>
            }
        </section>
    )
}

export default AddEquipment