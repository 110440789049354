import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router';
import axios from 'axios';
// Material
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

const VerifyEmail = () => {
    const navigate = useNavigate()
    const { vkey } = useParams()
    useEffect(() => {
        verify()
        document.title = 'Hästgruppen | Verifiera konto';
    }, [])

    let formData = new FormData();


    const verify = () => {

        formData.append('vkey', vkey)


        axios({
            method: 'POST',
            url: `${process.env.REACT_APP_SERVER_LINK}/api/users/api-verify-account.php`,
            data: formData,
            headers: { 'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}` }
        }).then(function (response) {
            // navigate("/login")
            console.log(response.data)
        }).catch(function (response) {
            console.log(response);
        });
    }

    const toLogin = () => {
        navigate('/login')
    }

    return (
        <section>
            <div className='register-container'>
                <h1>Verifiera ditt konto</h1>
                <div className='register-content'>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div><p>Ditt konto har verifierad</p></div>
                    </Box>
                </div>

            </div>
        </section>
    )
}

export default VerifyEmail