import React, { useEffect, useState } from 'react'
import axios from 'axios';
import ItemCard from '../../Components/ItemCard'
import { useNavigate } from "react-router-dom";

const Favorite = ({id, authorized}) => {


    const [dataList, setDataList] = useState([])
    const navigate = useNavigate()

    useEffect(() => {
        if (!authorized) {
            navigate('/')
        }
        loadData()
    }, [])

    const loadData = () => {
        axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/like/api-get-liked-product.php`, {
            params: {
                user_ID: id
            },
            headers: {
                'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
            }
        }).then((response) => {
            setDataList(response.data)
        })

    }
    return (
        <section className='favorite-section' >
            <div className='favorite-container'>
                <h1>Mina favoriter</h1>
                <div className='favorite-content'>
                    {dataList.map((val, i) => {
                        return (
                            <ItemCard
                                key={i}
                                title={val.title}
                                description={val.description}
                                price={val.price}
                                old={val.age}
                                height={val.manHeight}
                                place={val.place}
                                gender={val.gender}
                                race={val.race}
                                targeting={val.targeting}
                                id={val.product_ID}
                                uid={id}
                                authorized={authorized}
                                link={val.link_name}
                                serviceCat={val.category}
                                img={`${process.env.REACT_APP_SERVER_LINK}/upload/${val.image}`}

                            />
                        )
                    })}
                </div>
            </div>
            <div className='favorite-announce'>

            </div>
        </section>
    )
}

export default Favorite