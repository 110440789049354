import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios';
import { useParams } from 'react-router';
import { useNavigate } from "react-router-dom";
import { HeaderContext } from '../../helper/Context';
import { Helmet } from 'react-helmet';
// Materila
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
// Components
import ViewItemCard from '../../Components/ViewItemCard'
import ViewContactCard from '../../Components/ViewContactCard'
const Item = ({ id, authorized }) => {

    const { link } = useParams()
    const navigate = useNavigate()

    const [load, setLoad] = useState(false)
    const [dataList, setDataList] = useState([])
    const [owner, setOwner] = useState(false)

    useEffect(() => {
        loadData()
    }, [])

    const {
        pageSettings
    } = useContext(HeaderContext)

    const loadData = () => {
        setLoad(false)
        axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/product/api-get-product-link.php`, {
            params: {
                link: link
            },
            headers: {
                'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
            }
        }).then((response) => {
            setDataList(response.data[0])
            if (parseInt(response.data[0].status) !== 1) {
                navigate('/')
            }
            if (response.data[0].user_ID === id) {
                setOwner(true)
            }
            axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/product/api-get-product-image.php`, {
                params: {
                    id: response.data[0].product_ID
                },
                headers: {
                    'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
                }
            }).then((response) => {
            })
            setLoad(true)
        })
    }
    return (
        <section>
            <Helmet>
                {
                    pageSettings.length > 0 ?
                        pageSettings.find(row => row.tag === "item").metaTags
                            .map((val, i) => {
                                return (
                                    <meta key={i} name={val.name} content={val.content} />
                                )
                            })
                        : null
                }
            </Helmet>
            <h2>Sökresultat</h2>

            <div className='view-item-container'>
                {load ?
                    <div className='view-item-info-content'>
                        <ViewItemCard
                            img={dataList.image}
                            title={dataList.title}
                            place={dataList.place}
                            race={dataList.race}
                            targeting={dataList.targeting}
                            gender={dataList.gender}
                            old={dataList.age}
                            height={dataList.manHeight}
                            name={dataList.name}
                            origin={dataList.originCountry}
                            color={dataList.color}
                            price={dataList.price}
                            desc={dataList.description}
                            authorized={authorized}
                            id={dataList.product_ID}
                            uid={dataList.user_ID}
                            serviceCat={dataList.category}
                            equipmentCat={dataList.equip_cat}
                            equipmentSubCat={dataList.equip_subcat}
                            equipmentCondition={dataList.equip_condition}
                            equipmentMake={dataList.make}
                            equipmentModel={dataList.model}
                            equipmentTotalWeight={dataList.totalWeight}
                            equipmentLoadWeight={dataList.totalWeight}
                            link_name={dataList.link_name}
                            owner={owner}
                            uuid={id}
                        />
                    </div>
                    :
                    <Box sx={{ width: '100%' }}>
                        <LinearProgress />
                    </Box>
                }
                {load ?
                    <div className='view-item-contact-content'>
                        <ViewContactCard
                            uid={dataList.user_ID}

                        />
                    </div>
                    :
                    <Box sx={{ width: '100%' }}>
                        <LinearProgress />
                    </Box>
                }
            </div>
        </section>
    )
}

export default Item