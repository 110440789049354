import React from 'react'
import { useNavigate } from 'react-router-dom'

const CategoryBox = ({ title, link }) => {

    const navigate = useNavigate()

    
    return (
        <div className='category-box' onClick={() => navigate(`/add/${link}`)}>
            <p>{title}</p>
        </div>
    )
}

export default CategoryBox