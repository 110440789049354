import React from 'react'
// Components
import UserChat from '../../Components/UserChat'
import ChatCard from '../../Components/ChatCard'
const Chat = () => {
    return (
        <section >
        <div>
            <h1>Meddelande</h1>
            <div className='message-content'>
                <div className='message-content-users'>
                    <UserChat />
                </div>
                <div className='message-content-chat'>
                    <ChatCard />
                </div>
            </div>
        </div>
    
    </section>
    )
}

export default Chat