import React, { useEffect, useState } from 'react'
import { useNavigate, Link } from "react-router-dom";
import axios from 'axios';
// Material
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import validator from 'validator';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const Register = ({ authorized }) => {

    const navigate = useNavigate()

    useEffect(() => {
        if (authorized) {
            navigate('/')
        }
        document.title = 'Hästgruppen | Nytt konto';
    }, [authorized])

    let formData = new FormData();

    const [fname, setFname] = useState('')
    const [lname, setLname] = useState('')
    const [email, setemail] = useState('')
    const [password, setPassword] = useState('')
    const [phonenumber, setPhonenumber] = useState('')
    const [address, setAddress] = useState('')
    const [zipcode, setZipcod] = useState('')
    const [city, setCity] = useState('')
    const [lan, setLan] = useState('')
    const [country, setCountry] = useState('')
    const [rule, setRule] = useState('1')

    const [errorMessage, setErrorMessage] = useState("");

    const [loading, setLoading] = useState(false)

    const [termsAccept, setTermsAccept] = useState(false)
    const [policyAccept, setPolicyAccept] = useState(false)

    const register = () => {
        setErrorMessage("")
        setLoading(true)
        if (!fname) {
            setErrorMessage("Förnamn måste inte vara tomt")
            setLoading(false)
            return
        }
        if (!lname) {
            setErrorMessage("Efternamn måste inte vara tomt")
            setLoading(false)
            return
        }
        if (!validator.isEmail(email)) {
            setErrorMessage("E-post är fel")
            setLoading(false)
            return
        }
        if (password.length < 6 || password.length > 16) {
            setErrorMessage("Lösenord måste vara mellan 6 och 16 tecken")
            setLoading(false)
            return
        }
        if (!policyAccept) {
            setLoading(false)
            setErrorMessage("Acceptera personuppgiftspolicy tack")
            return
        }
        if (!termsAccept) {
            setLoading(false)
            setErrorMessage("Acceptera vilkor tack")
            return
        }
        formData.append('fname', fname)
        formData.append('lname', lname)
        formData.append('email', email)
        formData.append('password', password)
        formData.append('phonenumber', phonenumber)
        formData.append('address', address)
        formData.append('city', city)

        formData.append('zipcode', zipcode)
        formData.append('lan', lan)
        formData.append('country', country)
        formData.append('rule', rule)

        axios({
            method: 'POST',
            url: `${process.env.REACT_APP_SERVER_LINK}/api/users/api-post-users.php`,
            data: formData,
            headers: { 'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}` }
        }).then(function (response) {
            console.log(response.data)
            if (response.data === 5 || response.data === '5') {
                setLoading(false)
                setErrorMessage('Den e-post är redan finns')
            } else {
                navigate('/')
            }
        }).catch(function (response) {
            console.log(response);
        });
    }

    return (
        <section>
            <div className='register-container'>
                <h1>Sakapa nytt konto</h1>
                <div className='register-content'>
                    <form>
                        <TextField required label="Förenamn" variant="outlined" fullWidth style={{ marginBottom: 24 }} onChange={(e) => setFname(e.target.value)} />
                        <TextField required label="Efternamn" variant="outlined" fullWidth style={{ marginBottom: 24 }} onChange={(e) => setLname(e.target.value)} />
                        <TextField required label="E-post" variant="outlined" fullWidth style={{ marginBottom: 24 }} onChange={(e) => setemail(e.target.value)} />
                        <TextField required label="Lösenord" type="password" variant="outlined" fullWidth style={{ marginBottom: 24 }} onChange={(e) => setPassword(e.target.value)} />
                        <TextField label="Telefonnummer" variant="outlined" fullWidth style={{ marginBottom: 24 }} onChange={(e) => setPhonenumber(e.target.value)} />
                        <TextField label="Adress" variant="outlined" fullWidth style={{ marginBottom: 24 }} onChange={(e) => setAddress(e.target.value)} />
                        <TextField label="Postnummer" variant="outlined" fullWidth style={{ marginBottom: 24 }} onChange={(e) => setZipcod(e.target.value)} />
                        <TextField label="Stad" variant="outlined" fullWidth style={{ marginBottom: 24 }} onChange={(e) => setCity(e.target.value)} />
                        <TextField label="Län" variant="outlined" fullWidth style={{ marginBottom: 24 }} onChange={(e) => setLan(e.target.value)} />
                        <TextField label="Land" variant="outlined" fullWidth style={{ marginBottom: 24 }} onChange={(e) => setCountry(e.target.value)} />
                        <FormGroup style={{ width: '100%' }}>
                            <FormControlLabel control={<Checkbox />} label={<p>Jag har läst och godkänner <Link target="_blank" rel="noopener noreferrer" to="/personal-data-policy" style={{ color: '#1D7ABF' }}>personuppgiftspolicy</Link></p>} onChange={(e) => setPolicyAccept(e.target.checked)} />
                            <FormControlLabel control={<Checkbox />} label={<p>Jag har läst och godkänner <Link target="_blank" rel="noopener noreferrer" to="/terms-of-use" style={{ color: '#1D7ABF' }}>användarvillkor</Link></p>} onChange={(e) => setTermsAccept(e.target.checked)} />
                        </FormGroup>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
                            <div></div>
                            <LoadingButton
                                loading={loading}
                                type="submit"
                                onClick={register} variant="contained" color="secondary" style={{ padding: '8px 32px' }}
                            >
                                Registrera
                            </LoadingButton>
                        </Box>
                    </form>
                    {errorMessage ? <Alert variant="filled" slyle={{ zIndex: 500 }} severity="error"> {errorMessage} </Alert> : null}

                </div>

            </div>
        </section>
    )
}

export default Register