import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { useNavigate, useLocation } from "react-router-dom";
// Materila
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Collapse from '@mui/material/Collapse';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import IconButton from '@mui/material/IconButton';
// icons
import SearchIcon from '@mui/icons-material/Search';

function valuetext(value) {
    return `${value}°C`;
}

const EquipmentSearchTap = () => {

    const navigate = useNavigate()


    const searchFilter = useLocation().search;
    const cat = new URLSearchParams(searchFilter).get('cat');
    const equipCat = new URLSearchParams(searchFilter).get('equipCat');
    const equipSubCat = new URLSearchParams(searchFilter).get('equipSubCat');
    const typeFilter = new URLSearchParams(searchFilter).get('type');
    const pricefrom = new URLSearchParams(searchFilter).get('pricefrom');
    const priceto = new URLSearchParams(searchFilter).get('priceto');
    const inputFilter = new URLSearchParams(searchFilter).get('input');


    const [load, setLoad] = useState(false)

    const [searchType, setSearchType] = useState(0)
    const [searchInput, setSearchInput] = useState("")

    const [categoryList, setCategoryList] = useState([])
    const [category, setCategory] = useState('');
    const selectCategory = (event) => {
        setCategory(event.target.value);
        loadSubCategory(event.target.value)

    };

    const [subCategoryList, setSubCategoryList] = useState([])
    const [subCategory, setSubCategory] = useState('')
    const selectSubCategory = (e) => {
        setSubCategory(e.target.value)
    }

    const [price, setPrice] = useState([0, 200000]);
    const changePrice = (event, newValue) => {
        setPrice(newValue);
    };

    useEffect(() => {
        setLoad(false)
        setCategoryList([])
        loadCategory()
        setTimeout(function () {
            if (cat === "equipment") {
                setPrice([parseInt(pricefrom), parseInt(priceto)])
                if (equipCat) {
                    setCategory(equipCat)
                }
                if (equipSubCat) {
                    loadSubCategory(equipCat)
                    setSubCategory(equipSubCat)
                }
                setSearchType(parseInt(typeFilter))
                if (searchInput) {
                    setSearchInput(inputFilter)
                }

            }

        }, 1000)
        setLoad(true)
    }, [])

    const loadCategory = () => {
        axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/category/api_get_sub_category.php`, {
            params: {
                id: 19
            },
            headers: {
                'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
            }
        }).then((response) => {
            setCategoryList(response.data)
        })
    }

    const loadSubCategory = (id) => {
        axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/category/api_get_sub_sub_category.php`, {
            params: {
                id: 19,
                sid: id
            },
            headers: {
                'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
            }
        }).then((response) => {
            setSubCategoryList(response.data)
        })
    }

    const search = () => {
        navigate(`/search?cat=equipment${category ? `&equipCat=${category}` : ''}${subCategoryList.length > 0 ? `&equipSubCat=${subCategory}` : ''}&pricefrom=${price[0]}&priceto=${price[1]}&type=${searchType}${searchInput ? `&input=${searchInput}` : ''}`)
        window.location.reload()

    }

    return (
        <div>
            <Collapse in={load} >

                {load ?
                    <div>

                        <RadioGroup value={searchType} row style={{ marginBottom: 16 }} onChange={(e) => setSearchType(e.target.value)} >
                            <FormControlLabel value={0} control={<Radio />} label="Säljes" />
                            <FormControlLabel value={1} control={<Radio />} label="Sökes" />
                        </RadioGroup>

                        <FormControl variant="outlined" fullWidth style={{ marginBottom: 16 }}>
                            <InputLabel htmlFor="horse-search-title">Vad letar du efter?</InputLabel>
                            <OutlinedInput
                                label="Vad letar du efter?"
                                endAdornment={<InputAdornment position="end"><SearchIcon /></InputAdornment>}
                                onChange={(e) => setSearchInput(e.target.value)}
                            />
                            <FormHelperText>Sök</FormHelperText>
                        </FormControl>


                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <InputLabel >Kategori</InputLabel>
                            <Select
                                value={category}
                                label="Kategori"
                                onChange={(e) => selectCategory(e)}
                            >
                                {categoryList.map((val, i) => {
                                    return <MenuItem key={i} value={val.sub_category_ID}>{val.name}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                        {subCategoryList.length > 0 ?
                            <FormControl fullWidth sx={{ mt: 2 }}>
                                <InputLabel >Underkategori</InputLabel>
                                <Select
                                    value={subCategory}
                                    label="Underkategori"
                                    onChange={(e) => selectSubCategory(e)}
                                >
                                    {subCategoryList.map((val, i) => {
                                        return <MenuItem key={i} value={val.sub_sub_category_ID}>{val.name}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                            : null}


                        <Box sx={{ mt: 1 }}>
                            <Box sx={{ width: '100%' }}>
                                <p>Pris (SEK)</p>
                                <Slider
                                    value={price}
                                    onChange={changePrice}
                                    valueLabelDisplay="auto"
                                    getAriaValueText={valuetext}
                                    min={0}
                                    max={300000}
                                />
                            </Box>
                        </Box>

                        <Box sx={{ display: 'flex', mt: 2 }}>
                            <Box sx={{ flex: 1 }}></Box>
                            <IconButton aria-label="Sök" style={{ backgroundColor: '#0092FF', color: '#fff', width: 50, height: 50 }} onClick={search}>
                                <SearchIcon />
                            </IconButton>
                        </Box>
                    </div>
                    :
                    null
                }
            </Collapse>

        </div>
    )
}

export default EquipmentSearchTap