import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { HeaderContext } from '../helper/Context'

const Footer = () => {

    const {
        settings
    } = useContext(HeaderContext)



    return (
        <footer>
            <div className='footer-content'>
                <div className='row'>
                    <h3>Följ oss</h3>
                    <p><a href={settings.facebook_link} target="_blank">Facebook</a></p>
                    <p><a href={settings.instagram_link} target="_blank">Instagram</a></p>

                </div>
                <div className='row'>
                    <h3>Kontakta oss</h3>
                    <Link to="/add" ><p className='active'>Annonsera</p></Link>
                    <a href={`mailto: hastgruppen@support.se`}><p className='active'>info@hastgruppen.se </p></a>
                    <div className='address'>
                        <p>SRTC/Hästgruppen</p>
                        <p>Skogsborgsvägen 96 </p>
                        <p>29192 KRISTIANSTAD</p>
                    </div>
                </div>
                <div className='row'>
                    <h3>Villkor</h3>
                    <p><Link to="/terms-of-use">Användarvillkor</Link></p>
                    <p><Link to="/personal-data-policy">Personuppgiftspolicy</Link></p>

                </div>
            </div>
            <div className='footer-right'>
                <p>© Hästgruppen Sverige AB 2022 - {new Date().getFullYear()}</p>
                <p>Drivs med Prorec-CMS</p>
            </div>
        </footer>
    )
}

export default Footer