import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { useNavigate } from "react-router-dom";
// Compoennts
import ItemCard from '../../Components/ItemCard'
import ItemCardEquipment from '../../Components/ItemCardEquipment'
import ItemCardServices from '../../Components/ItemCardServices'
// Material
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
const Announce = ({ id, authorized }) => {

    const [dataList, setDataList] = useState([])
    const navigate = useNavigate()

    useEffect(() => {
        if (!authorized) {
            navigate('/')
        }
        loadData()
    }, [authorized])

    const loadData = () => {
        axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/product/api-get-product-user.php`, {
            params: {
                user_ID: id
            },
            headers: {
                'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
            }
        }).then((response) => {
            setDataList(response.data)
            console.log(response.data)
        })
    }

    const [deleteDialop, setdeleteDialop] = useState(false);
    const dialogClose = () => {
        setdeleteDialop(false);
    };
    const [selectedID, setSelectedID] = useState(0);
    const onDelete = (id) => {
        setdeleteDialop(true);
        setSelectedID(id)
    }

    const confirmDelete = (id) => {
        axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/product/api-delete-product.php`, {
            params: {
                id: id,
            },
            headers: {
                'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
            }
        }).then((response) => {
            console.log(response.data)
            if (response.status === 200) {
                setDataList(dataList.filter(row => row.product_ID !== id))
                setdeleteDialop(false);
            }
        })
    }

    const render = () => {
        return (
            dataList.map((val, i) => {
                if (i <= 9) {
                    if (val.cat === "horse") {
                        return (
                            <ItemCard
                                key={i}
                                title={val.title}
                                description={val.description}
                                price={val.price}
                                old={val.age}
                                height={val.manHeight}
                                place={val.place}
                                gender={val.gender}
                                race={val.race}
                                targeting={val.targeting}
                                id={val.product_ID}
                                uid={id}
                                authorized={authorized}
                                editAble={true}
                                img={`${process.env.REACT_APP_SERVER_LINK}/upload/${val.image}`}
                                link={val.link_name}
                                onDelete={() => onDelete(val.product_ID)}
                                treatingStatus={val.status}
                            />
                        )
                    } else if (val.cat === "equipment") {
                        return (
                            <ItemCardEquipment
                                key={i}
                                title={val.title}
                                description={val.description}
                                price={val.price}
                                place={val.place}
                                equipCat={val.equip_cat}
                                equipCondition={val.equip_condition}
                                equipSubcat={val.equip_subcat}
                                id={val.product_ID}
                                uid={id}
                                authorized={authorized}
                                link={val.link_name}
                                editAble={true}
                                img={`${process.env.REACT_APP_SERVER_LINK}/upload/${val.image}`}
                                onDelete={() => onDelete(val.product_ID)}
                                treatingStatus={val.status}


                            />
                        )
                    } else if (val.cat === "service") {
                        return (
                            <ItemCardServices
                                key={i}
                                title={val.title}
                                description={val.description}
                                price={val.price}
                                serviceCat={val.category}
                                place={val.place}
                                editAble={true}
                                id={val.product_ID}
                                uid={id}
                                authorized={authorized}
                                link={val.link_name}
                                img={`${process.env.REACT_APP_SERVER_LINK}/upload/${val.image}`}
                                onDelete={() => onDelete(val.product_ID)}
                                treatingStatus={val.status}


                            />
                        )
                    }



                }

            })
        )
    }


    return (
        <section className='favorite-section' >
            <div className='favorite-container'>
                <h1>Mina annonser</h1>
                <div className='favorite-content'>
                    {render()}
                </div>
            </div>
            <div className='favorite-announce'>

            </div>

            <Dialog open={deleteDialop} onClose={dialogClose} >
                <DialogTitle id="alert-dialog-title">
                    Är du sökert du vill att ta bort produkten?
                </DialogTitle>

                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Ta bort produkten
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button onClick={dialogClose} color="error">Nej</Button>
                    <Button onClick={() => confirmDelete(selectedID)} color="secondary" autoFocus>Ja</Button>
                </DialogActions>
            </Dialog>
        </section>
    )
}

export default Announce