import React, { useEffect, useState, useContext } from 'react'
import { HeaderContext } from '../helper/Context';

import axios from 'axios';
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Helmet } from 'react-helmet';


// Material
import IconButton from '@mui/material/IconButton';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import EditIcon from '@mui/icons-material/Edit';
// Assets
import iconLogo from '../Assets/prorec_icon.png'
const ViewItemCard = ({ uuid, uid, id, img, title, place, race, targeting, gender, old, height, name, origin, color, price, desc, authorized, serviceCat, equipmentCat, equipmentSubCat, equipmentCondition, equipmentMake, equipmentModel, equipmentTotalWeight, equipmentLoadWeight, owner, link_name }) => {

    const navigate = useNavigate()

    const {
        setLoginDialog
    } = useContext(HeaderContext)

    const [genderName, setGenderName] = useState("")
    const [raceName, setRaceName] = useState("")
    const [targetingName, setTargetingName] = useState("")
    const [colorNamn, setColoreName] = useState("")
    const [images, setImages] = useState([])
    let years = new Date(new Date() - new Date(old)).getFullYear() - 1970;

    const [serviceCategory, serServiceCategory] = useState("")
    const [equipmentCategory, serEquipmentCategory] = useState("")
    const [equipmentSubCategory, serEquipmentSubCategory] = useState("")


    const [likeIcon, setLikeIcon] = useState(false)

    useEffect(() => {
        if (targetingName) {
            loadOrientation()
        }
        if (race) {
            loadRace()
        }
        if (gender) {
            loadGender()
        }
        if (color) {
            loadColor()
        }
        if (serviceCat) {
            loadServiceCategory()
        }
        if (equipmentCat) {
            loadEquipmentCategory()
        }
        if (equipmentSubCat) {
            loadEquipmentSubCategory()
        }
        loadImages()
        if (authorized) (
            loadLike()
        )
    }, [authorized])

    // Load Data functions

    const loadOrientation = () => {
        axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/category/api_get_sub_sub_category_by_cat.php`, {
            params: {
                sid: 3
            },
            headers: {
                'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
            }
        }).then((response) => {
            setTargetingName(response.data.filter(row => row.sub_sub_category_ID === targeting)[0].name)

        })
    }

    const loadGender = () => {
        axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/category/api_get_sub_sub_category_by_cat.php`, {
            params: {
                sid: 1
            },
            headers: {
                'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
            }
        }).then((response) => {
            setGenderName(response.data.filter(row => row.sub_sub_category_ID === gender)[0].name)

        })
    }

    const loadRace = () => {
        axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/category/api_get_sub_sub_category_by_cat.php`, {
            params: {
                sid: 2
            },
            headers: {
                'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
            }
        }).then((response) => {
            setRaceName(response.data.filter(row => row.sub_sub_category_ID === race)[0].name)
        })
    }

    const loadColor = () => {
        axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/category/api_get_sub_sub_category_by_cat.php`, {
            params: {
                sid: 6
            },
            headers: {
                'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
            }
        }).then((response) => {
            setColoreName(response.data.filter(row => row.sub_sub_category_ID === color)[0].name)

        })
    }

    const loadLike = () => {
        console.log(id, uuid)
        axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/like/api-get-like.php`, {
            params: {
                id: id,
                user_ID: uuid
            },
            headers: {
                'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
            }
        }).then((response) => {
            if (response.data.length > 0) {
                setLikeIcon(true)
            } else {
                setLikeIcon(false)
            }
        })
    }

    const loadServiceCategory = () => {
        axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/category/api_get_sub_category.php`, {
            params: {
                id: 20
            },
            headers: {
                'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
            }
        }).then((response) => {
            serServiceCategory(response.data.filter(row => row.sub_category_ID === serviceCat)[0].name)
        })
    }

    const loadEquipmentCategory = () => {
        axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/category/api_get_sub_category.php`, {
            params: {
                id: 19
            },
            headers: {
                'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
            }
        }).then((response) => {
            serEquipmentCategory(response.data.filter(row => row.sub_category_ID === equipmentCat)[0].name)
        })
    }


    const loadEquipmentSubCategory = () => {
        axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/category/api_get_sub_sub_category.php`, {
            params: {
                id: 19,
                sid: equipmentCat
            },
            headers: {
                'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
            }
        }).then((response) => {
            serEquipmentSubCategory(response.data.filter(row => row.sub_sub_category_ID === equipmentSubCat)[0].name)
        })
    }


    // const loadedImages = [
    //     {
    //         id: 1,
    //         link: '',
    //         status: 'active'
    //     }
    // ]

    const [loadedImages, setLoadedImages] = useState([])
    const [activeImageIndex, setActiveImageIndex] = useState(0)
    const [expandImageStatus, setExpandImageStatus] = useState(false)
    const loadImages = () => {
        axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/product/api-get-product-image.php`, {
            params: {
                id: id
            },
            headers: {
                'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
            }
        }).then((response) => {
            setImages(response.data)
            setLoadedImages([])
            setLoadedImages(loadedImages => [...loadedImages, {
                link: img,
                name: "primary",
                status: 'active'
            }]);
            for (let i = 0; i < response.data.length; i++) {
                setLoadedImages(loadedImages => [...loadedImages, {
                    link: response.data[i].link,
                    name: response.data[i].name,
                    status: 'inactive'
                }]);
            }
        })
    }

    const expandImage = () => {
        // console.log(activeImageIndex)
        // console.log(loadedImages)
        setExpandImageStatus(true)
    }

    const shrinkImage = () => {
        setExpandImageStatus(false)

    }


    let formData = new FormData();
    const like = (status) => {
        if (authorized) {
            setLikeIcon(!likeIcon)
            formData.append('user_ID', uid)
            formData.append('product_ID', id)
            formData.append('status', status)
            axios({
                method: 'POST',
                url: `${process.env.REACT_APP_SERVER_LINK}/api/like/api-post-like.php`,
                data: formData,
                headers: { 'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}` }
            }).then(function (response) {
            }).catch(function (response) {
                console.log(response);
            });
        } else {
            setLoginDialog(true)
        }

    }


    const slickSettings = {
        dots: false,
        infinite: false,
        arrows: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        beforeChange: (oldIndex, newIndex) => {
            setActiveImageIndex(newIndex)
        }
    };

    const expandElickSettings = {
        dots: true,
        lazyLoad: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 2
    };



    return (
        <>

            <Helmet>
                <title>{title}</title>
                <meta name="description" content={desc} />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={desc} />
                {loadedImages.length > 0 ?
                    loadedImages.map((val, i) => {
                        return < meta key={i} property="og:image" content={`${process.env.REACT_APP_SERVER_LINK}/upload/${val.link}`} />
                    })

                    : null}
                <link rel="icon" type="image/png" href={iconLogo} />
                <link rel="apple-touch-icon" href={iconLogo} />
                {/* Add more meta tags, styles, or scripts here */}
            </Helmet>
            <div className='view-item-card'>

                <div className='view-item-card-main'>
                    {loadedImages.length > 0 ?
                        <div className='view-item-card-img'>
                            <Slider {...slickSettings}>
                                {loadedImages.map((val, i) => {
                                    return <img key={i} src={`${process.env.REACT_APP_SERVER_LINK}/upload/${val.link}`} alt={val.name} />
                                })}
                            </Slider>
                            <div className='img-expand'>
                                <IconButton aria-label="expand" onClick={() => expandImage()}>
                                    <FullscreenIcon />
                                </IconButton>
                            </div>

                            <div className='img-count'>
                                <CameraAltIcon />
                                <p>{loadedImages.length}</p>
                            </div>
                        </div>
                        :
                        null
                    }

                    {expandImageStatus ?
                        <div className='expanded-images'>
                            <div className='close-expanded-images'>
                                <IconButton aria-label="expand" onClick={() => shrinkImage()}>
                                    <FullscreenExitIcon />
                                </IconButton>
                            </div>
                            <div className='expanded-images-content'>
                                {loadedImages.length > 0 ?
                                    <div className=''>
                                        <Slider {...expandElickSettings}>
                                            {loadedImages.map((val, i) => {
                                                return (
                                                    <div className='expanded-images-content-cover' key={i}>
                                                        <img src={`${process.env.REACT_APP_SERVER_LINK}/upload/${val.link}`} alt={val.name} />
                                                    </div>
                                                )

                                            })}
                                        </Slider>
                                    </div>
                                    :
                                    null
                                }
                            </div>
                            <div className='overlay'></div>
                        </div>
                        :
                        null
                    }


                    <div className='view-item-card-content'>

                        <div className='view-item-card-content-header'>
                            <div className='view-item-card-content-header-title'>
                                <h1>{title}</h1>
                                <div className='view-item-card-content-header-main-cat'>
                                    {place ? <p>{place}</p> : null}
                                    {raceName ? <p>{raceName}</p> : null}
                                    {targetingName ? <p>{targetingName}</p> : null}
                                </div>
                            </div>
                            <div className='view-item-card-content-header-like'>
                                <IconButton aria-label="Like" onClick={likeIcon ? () => like(0) : () => like(1)}>
                                    {likeIcon ? <FavoriteIcon color="primary" /> : <FavoriteBorderIcon />}
                                </IconButton>
                                {
                                    authorized ?
                                        owner ?
                                            <IconButton aria-label="Navigate" onClick={() => navigate(`/edit/${link_name}`)}>
                                                <EditIcon color="primary" />
                                            </IconButton>
                                            : null
                                        : null
                                }

                            </div>
                        </div>

                        <div className='view-item-card-content-body'>
                            {height ? <p>Mankhöjd: {height} cm</p> : null}
                            {genderName ? <p>Kön: {genderName}</p> : null}
                            {old ? <p>Ålder: {years} år</p> : null}
                            {origin ? <p>Ursprungsland: {origin}</p> : null}
                            {targetingName ? <p>Inriktning: {targetingName}</p> : null}
                            {colorNamn ? <p>Färg: {colorNamn}</p> : null}
                            {raceName ? <p>Ras: {raceName}</p> : null}
                            {name ? <p>Namn: {name}</p> : null}
                            {serviceCategory ? <p>kategori: {serviceCategory}</p> : null}
                            {equipmentCategory ? <p>Kategori: {equipmentCategory}</p> : null}
                            {equipmentSubCategory ? <p>Underkategori: {equipmentSubCategory}</p> : null}
                            {equipmentCondition ? <p>Skick: {equipmentCondition === "0" ? "Oanvänd" : equipmentCondition === "1" ? "Nyskick" : equipmentCondition === "2" ? "Begagnad" : equipmentCondition === "3" ? "Sliten" : ''}</p> : ''}

                            {equipmentMake ? <p>Märke: {equipmentMake}</p> : null}
                            {equipmentModel ? <p>Modell: {equipmentModel}</p> : null}
                            {equipmentTotalWeight ? <p>Totalvikt: {equipmentTotalWeight} kg</p> : null}
                            {equipmentLoadWeight ? <p>Lastvikt: {equipmentLoadWeight} kg</p> : null}

                        </div>
                        <div className='view-item-card-content-footer'>
                            <p>{price} kr</p>
                        </div>
                    </div>
                </div>

                <div className='view-item-card-description'>
                    {desc}
                </div>
            </div>
        </>

    )
}

export default ViewItemCard