import React, { useEffect, useState, useContext } from 'react'
import { HeaderContext } from '../helper/Context';

import axios from 'axios';
import { useNavigate, Link } from "react-router-dom";

// Material
import IconButton from '@mui/material/IconButton';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
// Icons
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import HideImageIcon from '@mui/icons-material/HideImage';

const ItemCard = ({ authorized, uid, id, title, place, race, targeting, description, height, gender, old, price, img, isImg, editAble, link, onDelete, treatingStatus }) => {



    const navigate = useNavigate()

    const {
        setLoginDialog
    } = useContext(HeaderContext)

    const [status, setStatus] = useState({
        direction: "left",
        open: false,
        hidden: false
    });

    const handleClick = () => {
        setStatus(status => ({ ...status, open: !status.open }));
    };

    const [likeIcon, setLikeIcon] = useState(false)

    const [genderName, setGenderName] = useState("")
    const [raceName, setRaceName] = useState("")
    const [targetingName, setTargetingName] = useState("")
    let years = new Date(new Date() - new Date(old)).getFullYear() - 1970;

    const [imageCount, setImageCount] = useState('')

    useEffect(() => {
        if (targeting) {
            loadOrientation()
        }
        if (race) {
            loadRace()
        }
        if (gender) {
            loadGender()
        }
        loadImages()
        if (authorized) (
            loadLike()
        )
    }, [authorized])

    // Load Data functions

    const loadOrientation = () => {
        axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/category/api_get_sub_sub_category_by_cat.php`, {
            params: {
                sid: 3
            },
            headers: {
                'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
            }
        }).then((response) => {
            setTargetingName(response.data.filter(row => row.sub_sub_category_ID === targeting)[0].name)

        })
    }

    const loadGender = () => {
        axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/category/api_get_sub_sub_category_by_cat.php`, {
            params: {
                sid: 1
            },
            headers: {
                'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
            }
        }).then((response) => {
            setGenderName(response.data.filter(row => row.sub_sub_category_ID === gender)[0].name)

        })
    }

    const loadRace = () => {
        axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/category/api_get_sub_sub_category_by_cat.php`, {
            params: {
                sid: 2
            },
            headers: {
                'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
            }
        }).then((response) => {
            setRaceName(response.data.filter(row => row.sub_sub_category_ID === race)[0].name)
        })
    }




    const viewLessString = (string, length) => {
        let trimmedString = string.substring(0, length);
        if (string.length > trimmedString.length) {
            trimmedString = trimmedString + "..."
        }
        return trimmedString
    }

    const loadImages = () => {
        axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/product/api-get-product-image.php`, {
            params: {
                id: id
            },
            headers: {
                'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
            }
        }).then((response) => {
            setImageCount(response.data.length)
        })
    }

    const loadLike = () => {
        axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/like/api-get-like.php`, {
            params: {
                id: id,
                user_ID: uid
            },
            headers: {
                'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
            }
        }).then((response) => {
            if (response.data.length > 0) {
                setLikeIcon(true)
            } else {
                setLikeIcon(false)
            }
        })
    }

    let formData = new FormData();
    const like = (status) => {
        if (authorized) {
            setLikeIcon(!likeIcon)
            formData.append('user_ID', uid)
            formData.append('product_ID', id)
            formData.append('status', status)

            axios({
                method: 'POST',
                url: `${process.env.REACT_APP_SERVER_LINK}/api/like/api-post-like.php`,
                data: formData,
                headers: { 'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}` }
            }).then(function (response) {
            }).catch(function (response) {
                console.log(response);
            });
        } else {
            setLoginDialog(true)
        }

    }


    const treatingStatusRender = () => {
        if (treatingStatus === 0 || treatingStatus === "0") {
            return (
                <div className='treating-status treating-status-blue'>
                    <p>Under behandling</p>
                </div>
            )
        } else if (treatingStatus === 1 || treatingStatus === "1") {
            return (
                <div className='treating-status treating-status-green'>
                    <p>Accepterad</p>
                </div>
            )
        } else if (treatingStatus === 2 || treatingStatus === "2") {
            return (
                <div className='treating-status treating-status-red'>
                    <p>Avvisade</p>
                </div>
            )
        }
    }



    return (
        <div className='item-card' >
            <div className='item-card-img'>
                {isImg ?
                    <img src={img} alt="img" />
                    :
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}> <HideImageIcon sx={{ width: 70, height: 70, color: '#d2d2d2' }} /> </div>
                }
                {/* {img.indexOf('null') > -1 ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}> <HideImageIcon sx={{ width: 70, height: 70, color: '#d2d2d2' }} /> </div> : <img src={img} alt="img" />} */}
                {img.indexOf('null') > -1 ? null : <div className='img-count'> <CameraAltIcon /> <p>{parseInt(imageCount) === 0 ? isImg ? 1 : 0 : imageCount}</p> </div>}
            </div>
            <div className='item-card-content'>
                <div className='item-card-content-header'>
                    <div className='item-card-content-head'>
                        <Link to={`/item/${link}`}><h2>{viewLessString(title, 14)}</h2></Link>
                        {editAble ? null :
                            <IconButton aria-label="Like" onClick={likeIcon ? () => like(0) : () => like(1)}>
                                {likeIcon ? <FavoriteIcon color="primary" /> : <FavoriteBorderIcon />}
                            </IconButton>
                        }
                    </div>
                    <div className='item-card-main-catergory'>
                        {place ? <h3>{place}</h3> : ''}
                        {raceName ? <h3>{raceName}</h3> : ''}
                        {targetingName ? <h3>{targetingName}</h3> : ''}
                    </div>
                </div>
                <div className='item-card-content-body'>
                    <div className='item-card-content-description'>
                        {viewLessString(description, 50)}
                    </div>
                </div>
                <div className='item-card-content-footer'>
                    <div className='item-card-secondary-catergory'>
                        {height ? <p>Mankhöjd: {height} cm</p> : null}
                        {gender ? <p>Kön: {genderName}</p> : null}
                        {years ? <p>Ålder: {years} år</p> : null}
                    </div>
                    <div className='item-card-price'>
                        {price ? <p>{price} kr</p> : <p>Priset efter fråga</p>}
                    </div>

                </div>

                {editAble ? treatingStatusRender() : null}
            </div>
            {editAble ?

                <SpeedDial
                    ariaLabel="SpeedDial basic"
                    sx={{ position: 'absolute', bottom: '-28px', right: '50px', zIndex: 400 }}
                    icon={<MoreVertIcon />}
                    hidden={status.hidden}
                    onClick={handleClick}
                    open={status.open}
                    direction={status.direction}
                >
                    <SpeedDialAction
                        icon={<DeleteIcon />}
                        tooltipTitle="Radera"
                        onClick={onDelete}
                    />
                    <SpeedDialAction
                        icon={<EditIcon />}
                        tooltipTitle="Redigera"
                        onClick={() => navigate(`/edit/${link}`)}
                    />

                </SpeedDial>
                : null}


        </div>
    )
}

export default ItemCard