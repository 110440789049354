import { useState, useEffect, useContext } from 'react'
import { HeaderContext } from '../helper/Context';
import { useNavigate, Link } from "react-router-dom";
import axios from 'axios';
import Cookies from 'universal-cookie';

// Assets
import logo from '../Assets/logo.png'
// Material
import React from 'react'
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

// Icons
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SearchIcon from '@mui/icons-material/Search';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PublishIcon from '@mui/icons-material/Publish';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import BookmarksIcon from '@mui/icons-material/Bookmarks';

const Header = ({ mobileSize, tabletSize, username }) => {

    const navigate = useNavigate();

    const {
        setLoginDialog,
        authorized, setAuthorized,
        logout
    } = useContext(HeaderContext)

    const cookies = new Cookies();
    const token = cookies.get('jwt')

    const [selectedIndex, setSelectedIndex] = React.useState(0);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);


    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
        if (index === 0) {
            navigate("/")
        } else if (index === 1) {
            setLoginDialog(true)
        } else if (index === 2) {
            navigate("/search?cat=horse&agefrom=0&ageto=40&heightfrom=0&heightto=220&pricefrom=0&priceto=3000000&type=0")
        } else if (index === 3) {
            if (!authorized) {
                setLoginDialog(true)
            } else {
                navigate("/add")
            }
        } else if (index === 4) {
            setAnchorEl(event.currentTarget);
        }
    };

    const flexContainer = {
        display: 'flex',
        flexDirection: 'row',
        padding: 0,
    };

    const handleClose = () => {
        setAnchorEl(null);
    };





    const [mobileDetected, setMobileDetected] = useState(false)


    useEffect(() => {
        if (token) {
            axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/auth/login_verify.php`, {
                params: {
                    token: token
                },
                headers: {
                    'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
                }
            }).then((response) => {
                if (response.data.condition === false) {
                    setAuthorized(false)
                } else {
                    setAuthorized(true)
                }
            })
        } else {
            setAuthorized(false)
        }
        if (window.innerWidth <= 600) {
            setMobileDetected(true)
        }
    }, [token, navigate])




  

 

    return (
        <header>
            <div className='header-container'>
                <div className='logo' onClick={(event) => handleListItemClick(event, 0)}>
                    <img src={logo} alt="Logo" />
                </div>
                <List component="nav" aria-label="Menu" style={flexContainer}>

                    <ListItemButton
                        selected={selectedIndex === 3}
                        onClick={(event) => handleListItemClick(event, 3)}
                    >
                        <ListItemIcon>
                            <AddCircleIcon fontSize="large" />
                        </ListItemIcon>
                        <ListItemText primary={mobileDetected ? null : 'Lägg till annons'} />
                    </ListItemButton>

                    <ListItemButton
                        selected={selectedIndex === 2}
                        onClick={(event) => handleListItemClick(event, 2)}
                    >
                        <ListItemIcon>
                            <SearchIcon fontSize="large" />
                        </ListItemIcon>
                        <ListItemText primary={mobileDetected ? null : "Sökning"} />
                    </ListItemButton>


                    {authorized ?
                        <ListItemButton
                            selected={selectedIndex === 4}
                            onClick={(event) => handleListItemClick(event, 4)}
                        >
                            <ListItemIcon>
                                <AccountCircleIcon fontSize="large" />
                            </ListItemIcon>
                            <ListItemText primary={mobileDetected ? null : username} />
                            {mobileDetected ? null :
                                <ListItemIcon>
                                    <ExpandMoreIcon fontSize="large" />
                                </ListItemIcon>
                            }
                        </ListItemButton>
                        :
                        <ListItemButton
                            selected={selectedIndex === 1}
                            onClick={(event) => handleListItemClick(event, 1)}
                        >
                            <ListItemIcon>
                                <AccountCircleIcon fontSize="large" />
                            </ListItemIcon>
                            <ListItemText primary={mobileDetected ? null : "Logga in"} />
                        </ListItemButton>
                    }

                    <Menu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        <Link to="/account/favorite">
                            <MenuItem onClick={handleClose}>
                                <ListItemIcon>
                                    <BookmarksIcon fontSize="medium" />
                                </ListItemIcon>
                                Sparade annonser
                            </MenuItem>
                        </Link>

                        <Link to="/account/Monitored-searches">
                            <MenuItem onClick={handleClose}>
                                <ListItemIcon>
                                    <SearchIcon fontSize="medium" />
                                </ListItemIcon>
                                Bevakade sökningar
                            </MenuItem>
                        </Link>


                        <Link to="/account/announce">
                            <MenuItem onClick={handleClose}>
                                <ListItemIcon>
                                    <PublishIcon fontSize="medium" />
                                </ListItemIcon>
                                Mina annonser
                            </MenuItem>
                        </Link>

                        {/* <Link to="/account/message">
                            <MenuItem onClick={handleClose}>
                                <ListItemIcon>
                                    <ChatIcon fontSize="medium" />
                                </ListItemIcon>
                                Meddelanden
                            </MenuItem>
                        </Link> */}

                        <Link to="/account/profile">
                            <MenuItem onClick={handleClose}>
                                <ListItemIcon>
                                    <SettingsApplicationsIcon fontSize="medium" />
                                </ListItemIcon>
                                Profil
                            </MenuItem>
                        </Link>


                        <MenuItem onClick={logout}>
                            <ListItemIcon>
                                <ArrowForwardIcon fontSize="medium" />
                            </ListItemIcon>
                            Logga ut
                        </MenuItem>
                    </Menu>
                </List>
            </div>




        


        </header>
    )
}

export default Header