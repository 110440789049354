
import Resizer from 'react-image-file-resizer';

/* --- This function can resize any type on image --- */
export const resizeFile = async (file, maxWidth, callback) => {
    Resizer.imageFileResizer(
        file,
        maxWidth,
        maxWidth,
        'JPEG',
        80,
        0,
        (uri) => {
            const resizedImage = dataURLtoFile(uri, file.name);
            callback(resizedImage);
        },
        'base64'
    );
};
const dataURLtoFile = (dataURL, fileName) => {
    const arr = dataURL.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], fileName, { type: mime });
};
/* --- End of image resizing image --- */
