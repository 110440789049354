import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

// Matarial
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Alert from '@mui/material/Alert';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import LoadingButton from '@mui/lab/LoadingButton';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

// Icons
import PublishIcon from '@mui/icons-material/Publish';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
// Util
import { resizeFile } from '../../functions';
const Input = styled('input')({
    display: 'none',
});


const AddHorse = ({ id }) => {

    const [load, setLoad] = useState(false)
    const navigate = useNavigate()

    let formData = new FormData();

    const [title, setTitle] = useState("")
    const [name, setName] = useState("")
    const [race, setRace] = useState("")
    const [manHeight, setManHeight] = useState('')
    const [age, setAge] = useState('')
    const [gender, setGender] = useState('')
    const [targeting, setTargeting] = useState('')
    const [originCountry, setOriginCountry] = useState('')
    const [jumping, setJumping] = useState('')
    const [drassage, setDrassage] = useState('')
    const [horseID, setHorseID] = useState('')
    const [color, setColor] = useState('')
    const [price, setPrice] = useState('')
    const [place, setPlace] = useState('')
    const [tbdLink, setTbdLink] = useState('')
    const [blupLink, setBlupLink] = useState('')
    const [description, setDescription] = useState('')

    const [imageUrl, setImageUrl] = useState([])
    const [image, setImage] = useState([])

    const [primaryImageUrl, setPrimaryImageUrl] = useState(null)
    const [primaryImage, setPrimaryImage] = useState([])

    const [termsAccept, setTermsAccept] = useState(false)
    const [policyAccept, setPolicyAccept] = useState(false)


    const [orientationList, setOrientationList] = useState([])
    const [raceList, setRaceList] = useState([])
    const [genderList, setGenderList] = useState([])
    const [jumpingList, setJumpingList] = useState([])
    const [drassageList, setDrassageList] = useState([])
    const [colorList, setColorList] = useState([])
    const [ageList, setAgeList] = useState([])

    useEffect(() => {
        setLoad(false)
        setRaceList([])
        setOrientationList([])
        setGenderList([])
        setJumpingList([])
        setDrassageList([])
        setColorList([])
        loadOrientation()
        loadRace()
        loadGender()
        loadJumping()
        loadDrassage()
        loadColor()

        const currentYear = (new Date()).getFullYear();
        const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + (i * step));
        setAgeList(range(currentYear, currentYear - 50, -1));

        setLoad(true)
    }, [])


    // Load Data functions
    const loadOrientation = () => {
        axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/category/api_get_sub_sub_category_by_cat.php`, {
            params: {
                sid: 3
            },
            headers: {
                'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
            }
        }).then((response) => {
            setOrientationList(response.data)
        })
    }

    const loadRace = () => {
        axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/category/api_get_sub_sub_category_by_cat.php`, {
            params: {
                sid: 2
            },
            headers: {
                'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
            }
        }).then((response) => {
            setRaceList(response.data)
        })
    }

    const loadGender = () => {
        axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/category/api_get_sub_sub_category_by_cat.php`, {
            params: {
                sid: 1
            },
            headers: {
                'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
            }
        }).then((response) => {
            setGenderList(response.data)

        })
    }

    const loadJumping = () => {
        axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/category/api_get_sub_sub_category_by_cat.php`, {
            params: {
                sid: 5
            },
            headers: {
                'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
            }
        }).then((response) => {
            setJumpingList(response.data)
        })
    }

    const loadDrassage = () => {
        axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/category/api_get_sub_sub_category_by_cat.php`, {
            params: {
                sid: 4
            },
            headers: {
                'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
            }
        }).then((response) => {
            setDrassageList(response.data)
        })
    }

    const loadColor = () => {
        axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/category/api_get_sub_sub_category_by_cat.php`, {
            params: {
                sid: 6
            },
            headers: {
                'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
            }
        }).then((response) => {
            setColorList(response.data)
        })
    }

    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState("");

    const add = () => {
        setLoading(true)
        setErrorMessage("")

        if (!title) {
            setLoading(false)
            setErrorMessage("Fyll titel tack")
            return
        }
        if (!name) {
            setLoading(false)
            setErrorMessage("Fyll namn tack")
            return
        }
        if (!race) {
            setLoading(false)
            setErrorMessage("Ange ras tack")
            return
        }
        if (!manHeight) {
            setLoading(false)
            setErrorMessage("Skriv mankhöjden tack")

            return
        }
        if (!age) {
            setLoading(false)
            setErrorMessage("Ange födelsedag tack")
            return
        }
        if (!gender) {
            setLoading(false)
            setErrorMessage("Ange kön tack")
            return
        }
        if (!targeting) {
            setLoading(false)
            setErrorMessage("Ange hästens inriktning tack")
            return
        }
        if (!originCountry) {
            setLoading(false)
            setErrorMessage("Fyll hästens ursprungsland tack")
            return
        }
        if (!price) {
            setLoading(false)
            setErrorMessage("Fyll pris tack")
            return
        }
        if (!place) {
            setLoading(false)
            setErrorMessage("Fyll plats (var befinner sig hästen) tack")
            return
        }
        if (!description) {
            setLoading(false)
            setErrorMessage("Fyll beskrivning tack")
            return
        }
        if (!policyAccept) {
            setLoading(false)
            setErrorMessage("Acceptera personuppgiftspolicy tack")
            return
        }
        if (!termsAccept) {
            setLoading(false)
            setErrorMessage("Acceptera vilkor tack")
            return
        }


        formData.append('title', title)
        formData.append('price', price)
        formData.append('user_ID', id)
        formData.append('description', description)

        formData.append('name', name)
        formData.append('race', race)
        formData.append('manHeight', manHeight)
        formData.append('age', age)
        formData.append('gender', gender)
        formData.append('targeting', targeting)
        formData.append('originCountry', originCountry)
        formData.append('jumping', jumping)
        formData.append('drassage', drassage)
        formData.append('horseID', horseID)
        formData.append('color', color)
        formData.append('place', place)
        formData.append('tbdLink', tbdLink)
        formData.append('blupLink', blupLink)
        formData.append('cat', 'horse')

        for (let i = 0; i < image.length; i++) {
            formData.append("images[]", image[i]);
        }
        formData.append('images[]', image)

        for (let i = 0; i < primaryImage.length; i++) {
            formData.append("primaryImage[]", primaryImage[i]);
        }
        formData.append('primaryImage[]', primaryImage)

        axios({
            method: 'POST',
            url: `${process.env.REACT_APP_SERVER_LINK}/api/product/api-post-product.php`,
            data: formData,
            headers: { 'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}` }
        }).then(function (response) {
            navigate("/confirmation")
        }).catch(function (response) {
            console.log(response);
        });
    }


    const addImage = (e) => {
        const [file] = e.target.files;
        if (file) {
            for (let i = 0; i < e.target.files.length; i++) {
                resizeFile(e.target.files[i], 1024, (resizedImage) => {
                    setImage(image => [...image, resizedImage])
                    if (file && file.type.startsWith('image/')) {
                        setImageUrl(imageUrl => [...imageUrl, URL.createObjectURL(e.target.files[i])]);
                    } else {
                        console.log("error")
                    }
                });
            }
        }
    }

    const addPrimaryImage = (e) => {
        const [file] = e.target.files;
        if (file) {
            resizeFile(file, 1024, (resizedImage) => {
                setPrimaryImage(resizedImage)
                if (file && file.type.startsWith('image/')) {
                    setPrimaryImageUrl(URL.createObjectURL(file));
                } else {
                    console.log("error")
                }
            });
        }
    }


    const deletePrimaryImg = () => {
        setPrimaryImage([])
        setPrimaryImageUrl(null);
    }

    const deleteImg = (index) => {
        setImage(image => image.filter((img, i) => i !== index))
        setImageUrl(imageUrl => imageUrl.filter((img, i) => i !== index))
    }


    return (
        <section>
            <h1>Lägg till en annons (Hästar)</h1>
            {load ?
                <div className='adding-container'>
                    <div className='primary-image-container'>
                        <div className="primary-image-container">
                            <div className='images-container-primary-image-content'>
                                {primaryImageUrl ?
                                    <div className='primary-images-container-imgs' onClick={() => deletePrimaryImg()}>
                                        <img src={primaryImageUrl} alt="img" />
                                        <div className='primary-image-edit-view'>
                                            <CloseIcon fontSize="inherit" />
                                        </div>
                                    </div>
                                    :
                                    <label htmlFor="icon-button-file">
                                        <div className='add-img-container'>
                                            <Input accept="image/*" id="icon-button-file" type="file" onChange={addPrimaryImage} />
                                        </div>
                                        <div className='primary-image-container-none'>
                                            <p>Lägg till primär bild</p>
                                            <div className='primary-image-container-none-icon'>
                                                <AddPhotoAlternateIcon />
                                            </div>
                                        </div>
                                    </label>

                                }
                            </div>
                        </div>
                    </div>
                    <div className="images-container">
                        {imageUrl.length > 0 ?
                            <div className='images-container-imgs-content'>
                                {imageUrl.map((val, i) => {
                                    return (
                                        <div key={i} className='images-container-imgs'>
                                            <img src={val} alt="img" />
                                            <div className='delete-image'>
                                                <IconButton aria-label="delete" size="small" onClick={() => deleteImg(i)}>
                                                    <CloseIcon fontSize="inherit" />
                                                </IconButton>
                                            </div>
                                        </div>
                                    )
                                })}
                                <div className='images-container-add-more-img'>
                                    <IconButton color="primary" aria-label="upload picture" component="label">
                                        <input hidden accept="image/*" type="file" onChange={addImage} />
                                        <AddIcon />
                                    </IconButton>
                                </div>
                            </div>
                            :
                            <label htmlFor="icon-button-files">
                                <div className='add-img-container'>
                                    <Input accept="image/*" id="icon-button-files" multiple type="file" onChange={addImage} />
                                </div>
                                <div className='images-container-none'>
                                    <p>lägg till dina filer (bilder)</p>
                                    <div className='images-container-none-icon'>
                                        <AddPhotoAlternateIcon />
                                    </div>
                                </div>
                            </label>
                        }
                    </div>


                    <TextField label="Titel" required variant="outlined" onChange={(e) => setTitle(e.target.value)} />
                    <TextField label="Hästens namn" required variant="outlined" onChange={(e) => setName(e.target.value)} />
                    <FormControl required>
                        <InputLabel >Ras</InputLabel>
                        <Select
                            value={race}
                            label="Ras"
                            onChange={(e) => setRace(e.target.value)}
                        >
                            {raceList.map((val, i) => {
                                return <MenuItem key={i} value={val.sub_sub_category_ID}>{val.name}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                    <TextField label="Skriv mankhöjden i cm" required type="number" variant="outlined" onChange={(e) => setManHeight(e.target.value)} />
                    <FormControl required>
                        <InputLabel >Födelseår</InputLabel>
                        <Select
                            value={age}
                            label="Födelseår"
                            onChange={(e) => setAge(e.target.value)}
                        >
                            {ageList.map((val, i) => {
                                return <MenuItem key={i} value={val}>{val}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                    <FormControl required>
                        <InputLabel >Kön</InputLabel>
                        <Select
                            value={gender}
                            label="Kön"
                            onChange={(e) => setGender(e.target.value)}
                        >
                            {genderList.map((val, i) => {
                                return <MenuItem key={i} value={val.sub_sub_category_ID}>{val.name}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                    <FormControl required>
                        <InputLabel >Ange hästens inriktning</InputLabel>
                        <Select
                            value={targeting}
                            label="Ange hästens inriktning"
                            onChange={(e) => setTargeting(e.target.value)}
                        >
                            {orientationList.map((val, i) => {
                                return <MenuItem key={i} value={val.sub_sub_category_ID}>{val.name}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                    <TextField label="Hästens ursprungsland" required variant="outlined" onChange={(e) => setOriginCountry(e.target.value)} />
                    <FormControl >
                        <InputLabel >Hoppning (tävlingsnivå)</InputLabel>
                        <Select
                            value={jumping}
                            label="Hoppning (tävlingsnivå)"
                            onChange={(e) => setJumping(e.target.value)}
                        >
                            {jumpingList.map((val, i) => {
                                return <MenuItem key={i} value={val.sub_sub_category_ID}>{val.name} cm</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                    <FormControl >
                        <InputLabel >Dressyr (tävlingsnivå)</InputLabel>
                        <Select
                            value={drassage}
                            label="Dressyr (tävlingsnivå)"
                            onChange={(e) => setDrassage(e.target.value)}
                        >
                            {drassageList.map((val, i) => {
                                return <MenuItem key={i} value={val.sub_sub_category_ID}>{val.name}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                    <TextField label="Ange hästens ID-nummer" variant="outlined" onChange={(e) => setHorseID(e.target.value)} />
                    <FormControl >
                        <InputLabel >Färg</InputLabel>
                        <Select
                            value={color}
                            label="Färg"
                            onChange={(e) => setColor(e.target.value)}
                        >
                            {colorList.map((val, i) => {
                                return <MenuItem key={i} value={val.sub_sub_category_ID}>{val.name}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                    <TextField label="Pris (SEK)" required type="number" variant="outlined" onChange={(e) => setPrice(e.target.value)} />
                    <TextField label="Plats (var befinner sig hästen)" required variant="outlined" onChange={(e) => setPlace(e.target.value)} />
                    <TextField label="TDB-länk" variant="outlined" onChange={(e) => setTbdLink(e.target.value)} />
                    <TextField label="Blup-länk" variant="outlined" onChange={(e) => setBlupLink(e.target.value)} />
                    <TextField
                        className='description-field'
                        required
                        label="Beskrivning"
                        multiline
                        rows={4}
                        onChange={(e) => setDescription(e.target.value)}
                    />


                    <FormGroup style={{ width: '100%' }}>
                        <FormControlLabel control={<Checkbox />} label={<p>Jag har läst och godkänner <Link target="_blank" rel="noopener noreferrer" to="/personal-data-policy" style={{ color: '#1D7ABF' }}>personuppgiftspolicy</Link></p>} onChange={(e) => setPolicyAccept(e.target.checked)} />
                        <FormControlLabel control={<Checkbox />} label={<p>Jag har läst och godkänner <Link target="_blank" rel="noopener noreferrer" to="/terms-of-use" style={{ color: '#1D7ABF' }}>användarvillkor</Link></p>} onChange={(e) => setTermsAccept(e.target.checked)} />
                    </FormGroup>


                    <LoadingButton
                        loading={loading}
                        type="submit"
                        onClick={add} variant="contained" color="secondary" sx={{ mt: 2 }}
                        endIcon={<PublishIcon />}
                    >
                        Publicera
                    </LoadingButton>



                    {errorMessage ? <Alert variant="filled" severity="error"> {errorMessage} </Alert> : null}

                </div>
                :
                <Box sx={{ width: '100%' }}>
                    <LinearProgress />
                </Box>
            }
        </section>
    )
}

export default AddHorse