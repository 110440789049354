import React, { useEffect, useState, useContext } from 'react'
import axios from 'axios';
import { HeaderContext } from '../../helper/Context';
import { Helmet } from 'react-helmet';

// Material
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
// Icons
import AddCircleIcon from '@mui/icons-material/AddCircle';
// Assets
import hg1 from '../../Assets/hg1.png'
// Components
import SearchBox from '../../Components/SearchBox/SearchBox'
import ItemCard from '../../Components/ItemCard'
import ItemCardEquipment from '../../Components/ItemCardEquipment';
import ItemCardServices from '../../Components/ItemCardServices';
const Home = ({ id, authorized }) => {

    useEffect(() => {
        loadData()
    }, [])

    const {
        pageSettings
    } = useContext(HeaderContext)



    const [load, setLoad] = useState(false)
    const [dataList, setDataList] = useState([])
    const [image, setImage] = useState('')

    const loadData = () => {
        setLoad(false)
        axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/product/api-get-product.php`, {
            headers: {
                'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
            }
        }).then((response) => {
            setDataList(response.data.sort((a, b) => b.product_ID - a.product_ID).filter(row => row.status === '1' || row.status === 1))
            setLoad(true)
        })
    }

    const [dataPreviewLength, setDataPreviewLength] = useState(9)
    const [disableLoadMoreBtn, setDisableLoadMoreBtn] = useState(false)
    const loadMore = () => {
        setDataPreviewLength((prev) => prev + 10)
    }

    useEffect(() => {
        if (dataList.length > 0) {
            if (dataPreviewLength >= dataList.length) {
                setDisableLoadMoreBtn(true)
            }
        }
    }, [dataPreviewLength, dataList])




    const render = () => {
        return (
            dataList.map((val, i) => {
                if (i <= dataPreviewLength) {
                    if (val.cat === "horse") {
                        return (
                            <ItemCard
                                key={i}
                                title={val.title}
                                description={val.description}
                                price={val.price}
                                old={val.age}
                                height={val.manHeight}
                                place={val.place}
                                gender={val.gender}
                                race={val.race}
                                targeting={val.targeting}
                                id={val.product_ID}
                                uid={id}
                                authorized={authorized}
                                link={val.link_name}
                                isImg={val.image ? true : false}
                                img={`${process.env.REACT_APP_SERVER_LINK}/upload/${val.image}`}
                            />
                        )
                    } else if (val.cat === "equipment") {
                        return (
                            <ItemCardEquipment
                                key={i}
                                title={val.title}
                                description={val.description}
                                price={val.price}
                                place={val.place}
                                equipCat={val.equip_cat}
                                equipCondition={val.equip_condition}
                                equipSubcat={val.equip_subcat}
                                id={val.product_ID}
                                uid={id}
                                authorized={authorized}
                                link={val.link_name}
                                isImg={val.image ? true : false}
                                img={`${process.env.REACT_APP_SERVER_LINK}/upload/${val.image}`}
                            />
                        )
                    } else if (val.cat === "service") {
                        return (
                            <ItemCardServices
                                key={i}
                                title={val.title}
                                description={val.description}
                                price={val.price}
                                place={val.place}
                                serviceCat={val.Kategori}
                                id={val.product_ID}
                                uid={id}
                                authorized={authorized}
                                link={val.link_name}
                                isImg={val.image ? true : false}
                                img={`${process.env.REACT_APP_SERVER_LINK}/upload/${val.image}`}
                            />
                        )
                    }



                }
            })
        )
    }

    return (
        <div>
            <Helmet>
                {
                    pageSettings.length > 0 ?
                        pageSettings.find(row => row.tag === "homePage").metaTags
                            .map((val, i) => {
                                return (
                                    <meta key={i} name={val.name} content={val.content} />
                                )
                            })
                        : null
                }
            </Helmet>
            <div className='slider'>
                <img src={hg1} alt="hg1" />
            </div>
            <section>
                <div className='home-content'>
                    <SearchBox />
                    <h1>De senaste annonserna</h1>

                    {load ? render() : <Box sx={{ width: '100%', mb: 2 }}><LinearProgress /></Box>}

                    <Button disabled={disableLoadMoreBtn} className='special-button' variant="contained" endIcon={<AddCircleIcon />} color="secondary" fullWidth onClick={loadMore}>
                        Se mer
                    </Button>

                </div>
            </section>

        </div>
    )
}

export default Home