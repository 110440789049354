import React, { useEffect, useState, useContext } from 'react'
import { HeaderContext } from '../helper/Context';
import axios from 'axios';
import { useNavigate } from "react-router-dom";

// Material
import IconButton from '@mui/material/IconButton';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';

// Icons
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import FavoriteIcon from '@mui/icons-material/Favorite';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import HideImageIcon from '@mui/icons-material/HideImage';


const ItemCardEquipment = ({ authorized, uid, id, title, place, description, price, img, editAble, link, onDelete, equipCat, equipCondition, equipSubcat, treatingStatus }) => {



    const navigate = useNavigate()

    const {
        setLoginDialog
    } = useContext(HeaderContext)

    const [status, setStatus] = useState({
        direction: "left",
        open: false,
        hidden: false
    });

    const handleClick = () => {
        setStatus(status => ({ ...status, open: !status.open }));
    };

    const handleClose = () => {
        setStatus(status => ({ ...status, open: false }));
    };

    const [likeIcon, setLikeIcon] = useState(false)

    const [imageCount, setImageCount] = useState('')

    useEffect(() => {
        if (equipCat) {
            loadEquipCat()

        }
        if (equipSubcat) {
            loadSubEquipCat()
        }
        loadImages()
        if (authorized) (
            loadLike()
        )
    }, [authorized])





    const viewLessString = (string, length) => {
        let trimmedString = string.substring(0, length);
        if (string.length > trimmedString.length) {
            trimmedString = trimmedString + "..."
        }
        return trimmedString
    }

    const loadImages = () => {
        axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/product/api-get-product-image.php`, {
            params: {
                id: id
            },
            headers: {
                'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
            }
        }).then((response) => {
            setImageCount(response.data.length)
        })
    }

    const [equipCatName, setEquipCatName] = useState("")
    const [equipSubCatName, setEquipSubCatName] = useState("")




    const loadEquipCat = () => {
        axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/category/api_get_sub_category.php`, {
            params: {
                id: 19
            },
            headers: {
                'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
            }
        }).then((response) => {
            setEquipCatName(response.data.filter(row => row.sub_category_ID === equipCat)[0].name)
        })
    }


    const loadSubEquipCat = () => {
        axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/category/api_get_sub_sub_category.php`, {
            params: {
                id: 19,
                sid: equipCat
            },
            headers: {
                'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
            }
        }).then((response) => {
            setEquipSubCatName(response.data.filter(row => row.sub_sub_category_ID === equipSubcat)[0].name)
        })
    }

    const loadLike = () => {
        axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/like/api-get-like.php`, {
            params: {
                id: id,
                user_ID: uid
            },
            headers: {
                'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
            }
        }).then((response) => {
            if (response.data.length > 0) {
                setLikeIcon(true)
            } else {
                setLikeIcon(false)
            }
        })
    }

    let formData = new FormData();
    const like = (status) => {
        if (authorized) {
            setLikeIcon(!likeIcon)
            formData.append('user_ID', uid)
            formData.append('product_ID', id)
            formData.append('status', status)

            axios({
                method: 'POST',
                url: `${process.env.REACT_APP_SERVER_LINK}/api/like/api-post-like.php`,
                data: formData,
                headers: { 'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}` }
            }).then(function (response) {
            }).catch(function (response) {
                console.log(response);
            });
        } else {
            setLoginDialog(true)
        }

    }



    const editActions = [
        { icon: <DeleteIcon />, name: 'Radera' },
        { icon: <EditIcon />, name: 'Redigera' },
    ];

    const treatingStatusRender = () => {
        if(treatingStatus === 0 || treatingStatus === "0"){
            return (
                <div className='treating-status treating-status-blue'>
                    <p>Under behandling</p>
                </div>
            )
        }else if(treatingStatus === 1 || treatingStatus === "1"){
            return (
                <div className='treating-status treating-status-green'>
                    <p>Accepterad</p>
                </div>
            )
        }else if(treatingStatus === 2 || treatingStatus === "2"){
            return (
                <div className='treating-status treating-status-red'>
                    <p>Avvisade</p>
                </div>
            )
        }
    }

    return (
        <div className='item-card'>
            <div className='item-card-img'>
                {img.indexOf('null') > -1 ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}> <HideImageIcon sx={{ width: 70, height: 70, color: '#d2d2d2' }} /> </div> : <img src={img} alt="img" />}
                {img.indexOf('null') > -1 ? null : <div className='img-count'> <CameraAltIcon /> <p>{imageCount}</p> </div>}
            </div>
            <div className='item-card-content'>
                <div className='item-card-content-header'>
                    <div className='item-card-content-head'>
                        <h2 onClick={() => navigate(`/item/${link}`)}>{viewLessString(title, 14)}</h2>
                        {editAble ? null :
                            <IconButton aria-label="Like" onClick={likeIcon ? () => like(0) : () => like(1)}>
                                {likeIcon ? <FavoriteIcon color="primary" /> : <FavoriteBorderIcon />}
                            </IconButton>
                        }
                    </div>
                    <div className='item-card-main-catergory'>
                        {place ? <h3>{place}</h3> : ''}
                        {equipCondition ? <h3>{equipCondition === "0" ? "Oanvänd" : equipCondition === "1" ? "Nyskick" : equipCondition === "2" ? "Begagnad" : equipCondition === "3" ? "Sliten" : ''}</h3> : ''}
                    </div>
                </div>
                <div className='item-card-content-body'>
                    <div className='item-card-content-description'>
                        {viewLessString(description, 50)}
                    </div>
                </div>
                <div className='item-card-content-footer'>
                    <div className='item-card-secondary-catergory'>
                        {equipCatName ? <p>Kategori: {equipCatName}</p> : null}
                        {equipSubCatName ? <p>underkategori: {equipSubCatName}</p> : null}


                    </div>
                    <div className='item-card-price'>
                        {price ? <p>{price} kr</p> : <p>Priset efter fråga</p>}


                    </div>

                </div>
                {editAble ? treatingStatusRender() : null}

            </div>
            {editAble ?
                <SpeedDial
                    ariaLabel="SpeedDial basic"
                    sx={{ position: 'absolute', bottom: '-28px', right: '50px', zIndex: 400 }}
                    icon={<MoreVertIcon />}
                    hidden={status.hidden}
                    onClick={handleClick}
                    // onBlur={handleClose}
                    open={status.open}
                    direction={status.direction}
                >
                    <SpeedDialAction
                        icon={<DeleteIcon />}
                        tooltipTitle="Radera"
                        onClick={onDelete}
                    />
                    <SpeedDialAction
                        icon={<EditIcon />}
                        tooltipTitle="Redigera"
                        onClick={() => navigate(`/edit/${link}`)}
                    />

                </SpeedDial>
                : null}


        </div>
    )
}

export default ItemCardEquipment