import React, { useEffect, useState } from 'react'
import CategoryBox from '../../Components/CategoryBox'
import { useNavigate } from "react-router-dom";

const AddProduct = ({ authorized }) => {

    const navigate = useNavigate()

    useEffect(() => {
        if (!authorized) {
            navigate('/')
        }
        document.title = 'Hästgruppen | Lägg till annons';
    }, [authorized])

    return (
        <section>
            <h1>Lägg till en annons</h1>
            <h6>Välj en kategori</h6>
            <div className='category-selection-boxes'>
                <CategoryBox
                    title="Hästar"
                    link="horse"
                />
                <CategoryBox
                    title="Utrustning"
                    link="equipment"
                />
                <CategoryBox
                    title="Tjänster"
                    link="services"
                />
            </div>

        </section>
    )
}

export default AddProduct