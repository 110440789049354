import React, { useEffect, useState } from 'react'
import { useNavigate, Link } from "react-router-dom";
import axios from 'axios';
// Material
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
// Icons
import DeleteIcon from '@mui/icons-material/Delete';

const SavedLinks = ({ id, authorized }) => {


    const [dataList, setDataList] = useState([])
    const [dataListLoad, setDataListLoad] = useState(false)


    const navigate = useNavigate()
    useEffect(() => {
        if (!authorized) {
            navigate('/')
        }
    }, [authorized])

    const [searchList, setSearchList] = useState([])

    useEffect(() => {
        loadData()
        loadServiceCategory()
        loadOrientation()
        loadRace()
        loadGender()
        loadEquipmentCategory()
        loadEquipmentSubCategory()
    }, [])


    const loadData = () => {
        axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/savedlink/api-get-saved-link.php`, {
            params: {
                user_ID: id
            },
            headers: {
                'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
            }
        }).then((response) => {
            setSearchList([])
            for (let i = 0; i < response.data.length; i++) {
                const result = response.data[i].link.substring("search?".length);
                const params = new URLSearchParams(result);
                const paramsObject = {};
                for (const [key, value] of params.entries()) {
                    paramsObject[key] = value;
                }
                setSearchList(prev => [...prev, { ...paramsObject, id: response.data[i].saved_link }])
            }
            setDataList(response.data)
            setDataListLoad(true)
        })
    }

    const [serviceCategoryList, setServiceCategoryList] = useState([])
    const [serviceCategoryListLoad, setServiceCategoryListLoad] = useState(false)
    const loadServiceCategory = () => {
        axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/category/api_get_sub_category.php`, {
            params: {
                id: 20
            },
            headers: {
                'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
            }
        }).then((response) => {
            setServiceCategoryList(response.data)
            setServiceCategoryListLoad(true)
        })
    }

    const [orientationList, setOrientationList] = useState([])
    const [orientationListLoad, setOrientationListLoad] = useState(false)
    const loadOrientation = () => {
        axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/category/api_get_sub_sub_category_by_cat.php`, {
            params: {
                sid: 3
            },
            headers: {
                'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
            }
        }).then((response) => {
            setOrientationList(response.data)
            setOrientationListLoad(true)
        })
    }

    const [raceList, setRaceList] = useState([])
    const [raceListLoad, setRaceListLoad] = useState(false)
    const loadRace = () => {
        axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/category/api_get_sub_sub_category_by_cat.php`, {
            params: {
                sid: 2
            },
            headers: {
                'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
            }
        }).then((response) => {
            setRaceList(response.data)
            setRaceListLoad(true)
        })
    }

    const [genderList, setGenderList] = useState([])
    const [genderListLoad, setGenderListLoad] = useState(false)
    const loadGender = () => {
        axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/category/api_get_sub_sub_category_by_cat.php`, {
            params: {
                sid: 1
            },
            headers: {
                'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
            }
        }).then((response) => {
            setGenderList(response.data)
            setGenderListLoad(true)
        })
    }

    const [equipCatList, setEquipCatList] = useState([])
    const [equipCatListLoad, setEquipCatListLoad] = useState(false)
    const loadEquipmentCategory = () => {
        axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/category/api_get_sub_category.php`, {
            params: {
                id: 19
            },
            headers: {
                'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
            }
        }).then((response) => {
            setEquipCatList(response.data)
            setEquipCatListLoad(true)

        })
    }

    const [equipSubCatList, setEquipSubCatList] = useState([])
    const [equipSubCatListLoad, setEquipSubCatListLoad] = useState(false)
    const loadEquipmentSubCategory = () => {
        axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/category/api_get_sub_sub_category_by_catT.php`, {
            params: {
                sid: 19,
            },
            headers: {
                'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
            }
        }).then((response) => {
            setEquipSubCatList(response.data)
            setEquipSubCatListLoad(true)
        })
    }

    const keyMappings = {
        "cat": "Kategori",
        "agefrom": "År från",
        "ageto": "År till",
        "heightfrom": "Höjd från",
        "heightto": "Höjd till",
        "pricefrom": "Pris från",
        "priceto": "Pris till",
        "serviceCat": "Tjänster kategori",

        "orientation": "Inriktning",
        "race": "Ras",
        "gender": "Kön",

        "equipCat": "Utrustning kategorui",
        "equipSubCat": "Utrustning under kategorui",


        "type": "Type",
        "id": "ID"
    };

    const getDisplayName = (key) => {
        return keyMappings[key] || key;
    };

    const excludedKeys = ["id", "type"];

    let formData = new FormData();
    const deleteSavedLink = (e, id) => {
        e.stopPropagation()
        e.preventDefault()
        formData.append('id', id)
        axios({
            method: 'POST',
            url: `${process.env.REACT_APP_SERVER_LINK}/api/savedlink/api-delete-saved-link.php`,
            data: formData,
            headers: { 'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}` }
        }).then(function (response) {
            setDataList(dataList.filter(row => parseInt(row.saved_link) !== parseInt(id)))
        }).catch(function (response) {
            console.log(response);
        });

    }

    return (
        <section className='favorite-section' >
            <div className='favorite-container'>
                <h1>Bevakade sökningar</h1>
                {dataListLoad && serviceCategoryListLoad && orientationListLoad && raceListLoad && genderListLoad && equipCatListLoad && equipSubCatListLoad ?
                    <div className='favorite-content'>
                        {dataList.map((val, i) => {


                            return (
                                <Link key={i} to={`/${val.link}`}>
                                    <div className='saved-link-container' style={{ position: 'relative' }}>
                                        <p>Sök {i + 1}</p>

                                        <div style={{ marginTop: '16px', display: 'flex', flexWrap: 'wrap', gap: '8px' }}>

                                            {Object.keys(searchList.find(row => parseInt(row.id) === val.saved_link))
                                                .filter(key => !excludedKeys.includes(key))
                                                .map((key) => (
                                                    <Chip
                                                        label={
                                                            `${getDisplayName(key)}: 
                                                                 ${searchList.find(row => parseInt(row.id) === val.saved_link)[key] === "horse" ? 'Hästar'
                                                                : searchList.find(row => parseInt(row.id) === val.saved_link)[key] === "equipment" ? 'Utrustning'
                                                                    : searchList.find(row => parseInt(row.id) === val.saved_link)[key] === "service" ? 'Tjänstrt'
                                                                        : key === "serviceCat" ? serviceCategoryList.find(row => parseInt(row.sub_category_ID) === parseInt(searchList.find(row => parseInt(row.id) === val.saved_link)[key])).name
                                                                            : key === "orientation" ? orientationList.find(row => parseInt(row.sub_sub_category_ID) === parseInt(searchList.find(row => parseInt(row.id) === val.saved_link)[key])).name
                                                                                : key === "race" ? raceList.find(row => parseInt(row.sub_sub_category_ID) === parseInt(searchList.find(row => parseInt(row.id) === val.saved_link)[key])).name
                                                                                    : key === "gender" ? genderList.find(row => parseInt(row.sub_sub_category_ID) === parseInt(searchList.find(row => parseInt(row.id) === val.saved_link)[key])).name
                                                                                        : key === "equipCat" ? equipCatList.find(row => parseInt(row.sub_category_ID) === parseInt(searchList.find(row => parseInt(row.id) === val.saved_link)[key])).name
                                                                                            : key === "equipSubCat" ? equipSubCatList.find(row => parseInt(row.sub_sub_category_ID) === parseInt(searchList.find(row => parseInt(row.id) === val.saved_link)[key])).name
                                                                                                : searchList.find(row => parseInt(row.id) === val.saved_link)[key]
                                                            }`
                                                        }
                                                        color="primary"
                                                        key={key}
                                                    />

                                                ))}

                                        </div>

                                        <IconButton aria-label="delete" onClick={(e) => deleteSavedLink(e, val.saved_link)} style={{ position: 'absolute', right: 20, backgroundColor: '#1D7ABF' }}>
                                            <DeleteIcon style={{ fill: '#ffffff' }} />
                                        </IconButton>
                                    </div>

                                </Link>

                            )

                        })}

                    </div>
                    : null}
            </div>
            <div className='favorite-announce'>

            </div>
        </section>

    )
}

export default SavedLinks