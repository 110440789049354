import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import axios from 'axios';
// Material
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

const RePassword = ({ authorized }) => {

    const navigate = useNavigate()
    const [sendMessage, setSendMessage] = useState("")
    useEffect(() => {
        if (authorized) {
            navigate('/')
        }
        document.title = 'Hästgruppen | Nytt konto';
    }, [authorized])

    let formData = new FormData();

    const [email, setemail] = useState('')

    const repass = () => {
        formData.append('email', email)
        axios({
            method: 'POST',
            url: `${process.env.REACT_APP_SERVER_LINK}/api/repassword/api-send-link.php`,
            data: formData,
            headers: { 'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}` }
        }).then(function (response) {
            setSendMessage("Vi har skickat ett meddelande till din e-post")
        }).catch(function (response) {
            console.log(response);
        });
    }

    return (
        <section>
            {sendMessage ?
                <div className='register-container'>
                    <h1>Återställ lösenord</h1>
                    <div className='register-content'>
                        <p>{sendMessage}</p>
                    </div>
                </div>
                :
                <div className='register-container'>
                    <h1>Återställ lösenord</h1>
                    <div className='register-content'>
                        <TextField label="E-post" variant="outlined" fullWidth style={{ marginBottom: 24 }} onChange={(e) => setemail(e.target.value)} />
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div></div>
                            <Button variant="contained" onClick={repass}> ÅTERSTÄLL LÖSENORD </Button>
                        </Box>
                    </div>

                </div>
            }
        </section>
    )
}

export default RePassword