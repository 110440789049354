import React from 'react'

const PersonalDataPolicy = () => {
    return (
        <section >
            <article>
                <h1>Personuppgiftspolicy</h1>
                <p>
                    Att skydda våra användares personuppgifter är av största vikt för Hästgruppen.
                    Uppgifter som Hästgruppen tar del av när våra användare registrerar ett användarkonto är
                    namn, adress, telefonnummer och E-postadress. Uppgifterna kan komma att kompletteras
                    med information från tredje parter såsom IP-adresser samt även data relaterat till beteende
                    som användarna visar på webbsidan. Om du accepterar platstjänster samlar vi även in
                    löpande information om din geografiska position. Du kan när du vill slå av platstjänster.
                </p>
                <p>
                    Denna personuppgiftspolicy är en del av våra användarvillkor och tillämpas på alla tjänster
                    Hästgruppen levererar. Genom att använda tjänsterna godkänner du vår
                    personuppgiftspolicy. Om du inte samtycker till vår personuppgiftspolicy ber vi dig avstå från
                    annonsering och att i övrigt använda tjänsterna.
                </p>
                <p>
                    Registrering som användare:
                    När ett konto hos Hästgruppen skapas eller när ett konto ändras behövs personuppgifter för
                    att upprätta kontot. Denna typ av information delges Hästgruppen genom ett medvetet val av
                    användaren, såsom att fylla i ett formulär, genom att anmäla en misstänkt annons eller
                    genom att i övrigt kommunicera på Hästgruppen.
                </p>
                <p>
                    För att minska risken för bedragare kan Hästgruppen använda sig av tredjepartlösningar för
                    att verifiera användare. Ex registrering via sociala medier, googleinloggning, bank-id, e-mail
                    och sms-bekräftelser.
                    <br />
                    Hästgruppen ansvar inte förpersonuppgifter som hanteras av tredjepart och det är
                    användarens ansvar att säga upp godkännandet av sådana tredjepartlösningar får ta del av
                    användarens personuppgifter.
                </p>
                <p>
                    Information från andra källor: Vi kan ibland även få ta del av information från andra källor,
                    mätverktyg och andra tredjepartsleverantörer. Ex på sådan information är om användaren
                    registrerar sitt konto genom att koppla till sitt facebook-konto vilket ger Hästgruppen tillgång
                    till e-mailadress, namn och bild.
                </p>
                <p>
                    Ändamålet med behandlingen av personuppgifterna är att fullgöra vpr kundrelation och för
                    att leverera tjänsterna använder Hästgruppen användarnas personuppgifter. Vi behandlar
                    och delar med oss av dina personuppgifter till andra bolag och myndigheter i följande
                    huvudsakliga syften: Fullgöra våra avtalsenliga och lagenliga förpliktelser. Tillhandahålla och
                    administrera din tillgång till tjänsterna.
                    <br />
                    Besvara kundserviceärenden och utföra felrättning.
                    <br />
                    Förbättra din användarupplevelse.
                    <br />
                    Kunna ge dig annonser, erbjudanden och rekommendationer som är anpassade efter de
                    uppgifter vi har om dig.
                    < br />
                    Skicka dig information och direktmarknadsföring via e-post eller andra medier.
                    Förebygga, begränsa, och utreda missbruk av tjänsterna.
                </p>
                <p>
                    Vi kommer inte att dela, sälja, överlåta eller på annat sätt lämna ut personuppgifter utöver
                    vad som anges i denna personuppgiftspolicy om vi inte är skyldiga att göra det till följd av
                    rättslig skyldighet eller om vi först har fått ditt samtycke. Vi kan också, efter en
                    intresseavvägning, välja att lämna ut information till andra om det är nödvändigt för att
                    förhindra brott eller om informationen är nödvändig för att klargöra
                    specifika tvister om annonsobjekt. Dina personuppgifter lagras inte längre än nödvändigt och
                    Maximalt i fem år.
                </p>
                <p>
                    Du har rätt att när som helst begära att vi rättar eller raderar personuppgifter i enlighet med
                    vad som anges i tillämplig lag. Vill en användare radera sitt konto kan användaren skriva till
                    Hästgruppen.
                    <br />
                    Adress: Hästgruppen SRTC, Skogsborgsvägen 96, 29192 Kristianstad.
                    <br />
                    Det kan ta upp till 8 veckor att behandla en förfrågan om att radera sitt konto.
                </p>
                <p>
                    Information som lämnats till Hästgruppen i samband med annonsering kommer oavsett vad
                    som anges ovan att sparas i upp till fem år i syfte att begränsa missbruk av våra tjänster,
                    förhindra brottslig aktivitet och klarlägga tvister om annonsobjekt.
                </p>
                <p>
                    Vi kommer att regelbundet uppdatera denna personuppgiftspolicy.
                </p>
                <p>2022-03-30</p>
            </article>
        </section>
    )
}

export default PersonalDataPolicy