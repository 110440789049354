import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { useParams } from 'react-router';
import { useNavigate } from "react-router-dom";
// Compoenent
import EditHorse from './EditHorse';
import EditEquipment from './EditEquipment';
import EditService from './EditService';
const Edit = ({ id, authorized }) => {
    const { link } = useParams()
    const navigate = useNavigate()
    const [load, setLoad] = useState(false)
    const [cat, setCat] = useState('')
    const [dataList, setDataList] = useState([])
    const [imagesLink, setImagasLink] = useState([])

    useEffect(() => {
        if (!authorized) {
            navigate('/')
        }
        loadData()
    }, [])

    const loadData = () => {
        setLoad(false)
        axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/product/api-get-product-link.php`, {
            params: {
                link: link
            },
            headers: {
                'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
            }
        }).then((response) => {
            if (id !== response.data[0].user_ID) {
                navigate('/')
            }
            setCat(response.data[0].cat)
            setDataList(response.data[0])
            axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/product/api-get-product-image.php`, {
                params: {
                    id: response.data[0].product_ID
                },
                headers: {
                    'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
                }
            }).then((response) => {
                setImagasLink(response.data)
                setLoad(true)

            })
        })
    }

    return (
        <section>
            {
                cat === 'horse' ? <EditHorse id={id} dataList={dataList} imagesLink={imagesLink} /> :
                cat === 'equipment' ? <EditEquipment id={id} dataList={dataList} imagesLink={imagesLink} /> : 
                cat === 'service' ? <EditService id={id} dataList={dataList} imagesLink={imagesLink} /> : 

                
                null
            }

        </section>
    )
}

export default Edit