import React from 'react'

const IntegrationPolicy = () => {
    return (
        <section>
            <article>
                <h1>Användarvillkor på Hästgruppen</h1>
                <p>Almänt:</p>
                <p>
                    Webbsidan Hästgruppen ägs av Skepparslövs Rehabilitering och TräningsCenter (SRTC).
                    Tjänsterna tillhandahålls av Skepparslövs Rehabilitering och TräningsCenter och här i
                    texten förkortar vi det till SRTC. Alla avtal görs således med SRTC även om vi här i texten
                    använder oss av Webbsidans namn, Hästgruppen.
                </p>
                <p>
                    Dessa villkor tillsammans med övriga villkor på Hästgruppens webbsida och sådana villkor
                    som Hästgruppen (SRTC) i vissa fall tillhandahåller en användare separat, reglerar din
                    användning av Hästgruppens (SRTC) tjänster. Genom att använda tjänsterna godkänner du
                    dessa användarvillkor och förbinder dig att följa dom.
                </p>
                <p>
                    Hästgruppen är en marknadsplats där registrerade användare kan erbjuda att köpa, byta
                    och sälja olika produkter såsom varor, djur och tjänster. Detta är tillåtet under
                    förutsättning att köp, försäljning och byte av sådana produkter följer tillämpliga lagar,
                    regler och användarvillkor.
                </p>
                <p>
                    Användandet av Webbsidan är i sin grundversion är gratis men däremot kan kostnader
                    tillkomma från teleoperatörer för användning av data, vilket helt ska bäras av användaren.
                    Hästgruppen reserverar sig möjligheten att lägga till tilläggstjänster och abonnemang etc
                    och att ta ut en extra kostnad för nyttjandet av sådana mervärdesprodukter.
                </p>
                <p>
                    Hästgruppen (SRTC) är inte ansvarig för innehållet som genererats av användaren. För att
                    annonsera hos Hästgruppen måste du följa våra regler för annonsering. Vi förbehåller oss
                    rätten att blockera eller stänga av en användare som missbrukar, manipulerar eller
                    använder tjänsterna i strid med Användarvillkoren. Blockering av användares epostadress,
                    IPadress
                    eller andra identifieringsverktyg kan ske tillfälligt eller permanent.
                </p>
                <p>
                    För att ingå avtal om tjänsterna måste du vara 18 år gammal eller ha målsmans
                    godkännande.
                </p>
                <p>
                    Om du registrerar ett företag som användare intygar du att du har behörighet att binda
                    företaget till dessa Användarvillkor.
                </p>
                <p>
                    Hästgruppen (SRTC) är aldrig part i genomförandet av försäljning, byte eller köp av
                    produkter mellan användarna. Det sker exklusivt mellan användarna och Hästgruppen
                    (SRTC) aldrig kontraktspart.
                </p>
                <p>
                    All användning och kopiering av information och bilder på webbsidan för någon form av
                    affärssyften är förbjuden.
                </p>
                <p>
                    För att kunna använda Hästgruppens tjänster måste du registrera ett konto. Detta är
                    gratis för privatpersoner. När du registrerar dig binder du dig samtidigt till Hästgruppens
                    Användarvillkor. Man får inte upprätta mer än ett konto per person. Användaruppgifter
                    som delges Hästgruppen ska vara korrekta och hållas uppdaterade.
                </p>
                <p>
                    Hästgruppens Företagsannonser är till för företag. Där krävs ett av våra abonnemang för
                    att kunna publicera radannonser under säljes. Som företag räknas: Annonsör vars syfte
                    är att regelbundet förmedla varor och tjänster snarare än i egenskap av privatperson.
                    (Hästgruppen förbehåller sig rätten att avgöra när annons/annonser är företagsliknande.)
                    Juridiska personer. Annonsör med varor som säljs med avdragbar moms.
                    Företagsannonsörer åtar sig att följa Allmänna reklamationsnämndens
                    rekommendationer och konsumetskyddande lagar.
                </p>
                <p>
                    Priser ska anges inklusive moms.
                </p>
                <p>
                    Användargenererat innehåll. Med Användargenererat innehåll avses allt sådant innehåll
                    som en användare av en tjänst skapar och/eller lägger upp på Webbsidan. Tex bilder och
                    filmer.
                </p>
                <p>
                    Du garanterar att du har nödvändiga rättigheter till det användargenererade innehållet,
                    antingen att du själv skapat detta eller att alla som har medverkat har gett dig tillstånd att
                    använda det användargenererade innehållet på Webbsidan i enlighet med
                    Användarvillkoren. Detta innebär att du garanterar att användargenererat innehåll inte
                    innehåller immaterialrättsligt skyddat material, såsom musik, film, bild, logotyp eller annat
                    material som du inte har tillåtelse att använda.
                </p>
                <p>
                    Du garanterar att personer som kan identifieras i det användargenererade innehållet är
                    medvetna om hur materialet kommer att användas och att de har godkänt att medverka i
                    det användargenererade innehållet och att Hästgruppen (SRTC) även kan komma att
                    använda det användargenererade innehållet i marknadsföringssyfte. Genom att lägga upp
                    användargenererat innehåll på Webbsidan, tex en annons, ger du Hästgruppen (SRTC) en
                    obegränsad rätt att fritt förfoga över det användargenererade innehållet, genom att tex
                    bearbeta, formatanpassa, lagra eller kopiera det och göra det tillgängligt för allmänheten
                    oavsett mediekanal och att vidareupplåta dessa rättigheter till eventuella
                    samarbetspartners.
                </p>
                <p>
                    Hästgruppen (SRTC) får även använda det användargenererade innehållet i
                    marknadsföringssyfte. Hästgruppens (SRTC) rättigheter kvarstår även efter att en annons
                    har raderats. Du efterger härmed alla krav på ersättning från Hästgruppen (SRTC) för
                    Hästgruppens (SRTC) användning av det användargenererade innehållet.
                </p>
                <p>
                    Hästgruppen(SRTC) innehar de immateriella rättigheterna till tex bild, design och det
                    övriga material och information som görs tillgängligt för dig genom din användning av
                    tjänsterna. Samma sak gäller den bakomliggande programkoden för tjänsterna. Sådant
                    material får inte användas på annat sätt än inom ramen för användning av tjänsterna. Om
                    inte annat anges är allt material Hästgruppens (SRTC) exklusiva egendom.
                </p>
                <p>
                    Du får inte skriva ut, kopiera, reproducera, publicera, ladda upp, skicka eller distribuera
                    något material eller någon information på Webbsidan utan föregående skriftligt tillstånd
                    från Hästgruppen (SRTC).
                </p>
                <p>
                    All otillåten användning medför ersättningsskyldighet. Den som avsiktligt eller genom grov
                    oaktsamhet bryter mot lagen kan straffas med böter eller fängelse upp till två år och bli
                    dömd att betala skadestånd.
                </p>
                <p>
                    Personuppgifter:
                </p>
                <p>
                    Att skydda våra användares personuppgifter är av största vikt för Hästgruppen. I
                    Hästgruppens personuppgiftspolicy finns information om hur vi behandlar dina
                    personuppgifter och hur vi skyddar din integritet när du använder tjänsterna. Genom att
                    godkänna dessa användarvillkor samtycker du till den behandling av dina personuppgifter
                    som framgår av vår personuppgiftspolicy
                </p><br />
                <p>
                    Regler för annonsering :
                </p>
                <p>
                    Du ansvarar själv som användare för att den information som du lägger in i annonser inte
                    strider mot gällande användarvillkor, lagar och regler. Du som användare är personligen
                    ansvarig för din annons och köpare bör iaktta sedvanlig vaksamhet vid distansköp.
                    Hästgruppen medlar ej mellan parter vid tvister men förbehåller sig rätten att
                    oåterkalleligen blockera användare från tjänsten som inte följer Användarvillkoren.
                </p>
                <p>
                    Särskilda regler vid försäljning av hästar :
                </p>
                <p>
                    Att sälja hästar är generellt tillåtet på Hästgruppen, men måste uppfylla följande krav;
                    Beskrivningen och/eller bilden av hästen måste väljas med omsorg och respekt.
                </p>
                <p>
                    Köparen måste informera sig om ålder, ev sjukdomar eller andra onormala betingelser
                    med hästen
                </p>
                <p>
                    Det är inte tillåtet att sälja dräktiga hästar såvida inte köparen tydligt upplyser om detta.
                    (Dräktiga hästar får inte transporteras från två månader innan och fram till dess att stoet
                    fölar.)
                </p>
                <p>
                    Alla hästar ska ha hästpass och du som köpare ska aldrig godta att du kommer få passet
                    vid ett senare tillfälle, utan det ska följa med hästen. Detta är säljarens ansvar att se till.
                </p>
                <p>
                    Om du köper häst från ett annat land behöver hästen ha ett hälsointyg av officiell
                    veterinär innan den transporteras till Sverige.
                </p>
                <p>
                    Mer information om vad som gäller vid försäljning av häst finns bla på jordbruksverkets
                    hemsida.
                </p>
                <p>
                    Publceringstid :
                </p>
                <p>
                    Hästgruppens (SRTC) tjänst anses fullgjord när annonsen har godkänts och publicerats på
                    Webbsidan. Därefter måste du som användare plocka bort annonsen så snart syftet med
                    annonsen är uppfyllt, tex när du sålt varan. Hästgruppen vill minimera antalet inaktuella
                    annonser på Webbsidan och därför tas en annons bort av Hästgruppen efter tre månader
                    från publiceringsdatumet.
                </p>
                <p>
                    Hästgruppen förbehåller sig rätten att granska samtliga annonser och att neka eller
                    avlägsna en annons på grund av att den bryter mot annonseringsreglerna.
                </p>
                <p>
                    Hota, trakassera eller i övrigt agera anstötligt mot någon annan användare är inte tillåtet.
                    Hästgruppen kommer göra sitt bästa för att filtrera, ta bort eller stoppa dessa
                    meddelanden och/eller annonser.
                </p>
                <p>
                    Användning av spammeddelanden
                    eller meddelanden i reklamsyfte är inte tillåtet utan
                    föregående skriftligt tillstånd från Hästgruppen. Kommersiell användning av
                    meddelandetjänsten kan medföra skadestånd. Det är endast tillåtet att annonsera om
                    försäljning, uthyrning, byten, samt tjänster och sökes/efterlyses. Annonstexten ska
                    användas till att beskriva den specifika varan eller tjänsten som erbjuds i annonsen. Övrig
                    information m såsom beskrivning av en verksamhet eller ett generellt utbud tillåts inte i
                    annonstexten. Ej heller företagsnamn eller länkningar är tillåtna. Då räknas man som
                    företag och får annonsera som företagare.
                </p>
                <p>
                    Dubbletter är inte tillåtna. Radera den gamla annonsen först. Lägg ut den nya sen. Du kan
                    bara annonsera en vara/tjänst under EN kategori. Se till att annonsera under rätt
                    kategori.
                </p>
                <p>
                    Hästgruppen förbehåller sig rätten att neka eller ta bort eller flytta en annons som enligt
                    Hästgruppens bedömning är ovederhäftiga eller orealistiska .
                </p>
                <p>
                    Annonserna ska ha ett verkligt pris. Pris i stil med 1234567 är inte tillåtet.
                </p>
                <p>
                    Annonserade tjänster måste följa de lagar och föreskrifter som gäller för respektive
                    bransch.
                </p>
                <p>
                    Varor som inte är tillåtna att säljas enligt svensk lag får inte annonseras.
                </p>
                <p>
                    Stötande och kränkande innehåll tillåts inte. Vare sig text, bilder eller filmer.
                </p>
                <p>
                    Tredjepartsinformation/tilläggsinformation kan komma att visas i anslutning till din
                    annons. Sådan information visas automatiskt och är baserad på den information som du
                    som användare/annonsör lägger in. Tex kan viss kartinformation uppkomma baserat på
                    postnummer. Dessa uppgifter hämtas från externa system från tredje part och
                    Hästgruppen tar inget ansvar för eventuella felaktigheter. Du måste själv, som annonsör
                    kontrollera att dessa uppgifter är korrekta.
                </p>
                <p>
                    Länkar och hänvisningar i annonserna ska vara relevanta för din annons. Samma regler
                    gäller för dessa. Du som användare kan komma att ansvara för innehållet på den
                    webbsida du länkar till. Det är inte tillåtet att länka/hänvisa/referera till en annan annons
                    eller auktionswebbplats.
                </p>
                <p>
                    Det är inte tillåtet att använda annans logotyp i en annons (oavsett om logotypen är en del
                    av en bild el film) utöver sådan logotyp som finns på den vara som annonseras.
                </p>

                <p>
                    Marknadsföring : All marknadsföring ska enligt Marknadsföringen (2008:486) stämma
                    överens med god marknadsföringssed. Om ett företag bryter mot reglerna kan
                    Marknadsdomstolen förbjuda detta och om det fortsätter kan företaget dömas att betala
                    vite.
                </p>
                <p>
                    Det är uttryckligen förbjudet att skicka reklam via email,
                    sms eller telefonsamtal till
                    fysiska personer om inte dessa personer har samtyckt till det på förhand.
                </p>
                <p>
                    Ansvar:
                </p>
                <p>
                    Hästgruppen garanterar inte kontinuerlig, oavbruten eller säker tillgång till tjänsterna.
                    Driften av Webbsidan kan komma att störas av ett antal faktorer utanför Hästgruppens
                    kontroll och Hästgruppen (SRTC) ställer inga garantier gällande Webbsidans funktion eller
                    tillgänglighet. Hästgruppen (SRTC) kan inte göras ansvarigt för skador som direkt eller
                    indirekt orsakas av användning av Webbsidan och dess innehåll. Vid tekniska fel ersätts
                    högst annonskostnaden.
                </p>
                <p>
                    Webbsidan är huvudsakligen en annonsplats. Webbsidan är således ett forum som låter
                    användare annonsera, sälja och köpa varor och tjänster. Hästgruppen (SRTC) har ingen
                    kontroll över och medverkar inte på något vis vid ett byte, köp eller försäljning av någon
                    vara eller tjänst mellan en köpare och säljare. Hästgruppen ansvarar inte för den
                    utannonserade varan eller tjänsten. Ej heller för skada som beror på uteblivna och eller
                    fördröjda annonssvar eller på felaktig information i annonstexten.
                </p>
                <p>
                    Du som användare åtar dig att hålla Hästgruppen (SRTC) skadelöst för det fall att någon
                    tredje part ställer krav på ersättning på grund av Användargenererat innehåll eller på
                    grund av att du i övrigt har agerat i strid med dessa användarvillkor eller i strid med
                    gällande lag eller tredje parts rättighet.
                </p>
                <p>
                    Ångerrätt :
                </p>
                <p>
                    När du köper tjänster via internet har du som regel 14 dagars ångerrätt. För att
                    annonsera hos Hästgruppen måste du dock frånsäger dig din ångerrätt eftersom
                    Hästgruppens tjänst för annonsering innebär att du, genom att lägga in en annons, begär
                    att tjänsten påbörjas omedelbart efter att du betalat för den. Det innebär att du
                    accepterar att ångerrätten upphör när tjänsten har fullgjorts. Hästgruppens tjänst anses
                    fullgjord så snart din annons har publicerats. Motsvarande regler gäller för tilläggstjänster
                    som du köper i samband med annonsering.
                </p>
                <p>
                    Överlåtelse:
                </p>
                <p>
                    Genom att acceptera Användarvillkoren godkänner du att avtalet som du har ingått med
                    Hästgruppen (SRTC), utan ditt samtycke, kan överlåtas till annat bolag som helt eller
                    delvis, direkt eller indirekt ägs av SRTC eller till tredje part i anslutning till en inkråmseller
                    verksamhetsöverlåtelse som inkluderar tjänsterna.
                </p>
                <p>
                    Förändringar i tjänsterna och användarvillkoren :
                </p>
                <p>
                    Hästgruppen (SRTC) kan när som helst och utan föregående information besluta att
                    avveckla tjänsterna eller ersätta tjänsterna med andra tjänster. I sådana förbehåller sig
                    Hästgruppen (SRTC) rätten att avsluta din tillgång till tjänsterna, vilket kommer att ske
                    efter att du fått information om detta, tex genom din registrerade epostadress.
                    Hästgruppen kan komma att ändra dessa användarvillkor. I den mån vi gör väsentliga
                    förändringar som kräver ditt samtycke kommer vi dock att inhämta ditt samtycke innan
                    förändringarna träder i kraft.
                </p>
                <p>
                    Tillämplig lag och tvistelösning:
                </p>
                <p>
                    Svensk lag ska tillämpas på tjänsterna och dessa användarvillkor. Tvister ska avgöras av
                    svensk allmän domstol. Du kan också vända dig till Allmänna Reklamationsnämnden läs
                    mer på <a href="https://www.arn.se/" target="_blank" style={{color: 'blue'}}>www.arn.se</a>
                </p>
                <br />
                <br />
                <br />
                <p>Kontaktuppgifter :</p>
                <p>SRTC, Skogsborgsvägen 96, 291 92 KRISTIANSTAD.</p>
                <p>
                    Telefon 0701123377
                </p>
            </article>
        </section>
    )
}

export default IntegrationPolicy