import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";

import axios from 'axios';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';

import EditIcon from '@mui/icons-material/Edit';
import Alert from '@mui/material/Alert';

const Input = styled('input')({
    display: 'none',
});

const Profile = ({ id, authorized }) => {

    const navigate = useNavigate()
    let formData = new FormData();
    useEffect(() => {
        if (!authorized) {
            navigate('/')
        }
        loadData()
    }, [authorized])


    const [load, setLoad] = useState(false)
    const [dataList, setDataList] = useState([])

    const [fname, setFname] = useState('')
    const [lname, setLname] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')

    const [address, setAddress] = useState('')
    const [zipcode, setZipcode] = useState('')
    const [city, setCity] = useState('')
    const [lan, setLan] = useState('')
    const [country, setCountry] = useState('')

    const [imageUrl, setImageUrl] = useState('')
    const [image, setImage] = useState('')
    const [imageLink, setImageLink] = useState('')


    const [confirmation, setConfirmation] = useState("")

    const loadData = () => {
        setLoad(false)
        axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/users/api-get-one-user.php`, {
            params: {
                id: id
            },
            headers: {
                'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
            }
        }).then((response) => {
            setDataList(response.data[0])
            setFname(response.data[0].fname)
            setLname(response.data[0].lname)
            setEmail(response.data[0].user_email)
            setPhone(response.data[0].phonenumber)
            setAddress(response.data[0].address)
            setZipcode(response.data[0].zipcode)
            setCity(response.data[0].city)
            setLan(response.data[0].lan)
            setCountry(response.data[0].country)
            setImageLink(response.data[0].image)

            setLoad(true)
        })
    }

    const save = () => {
        formData.append('fname', fname)
        formData.append('lname', lname)
        formData.append('phone', phone)
        formData.append('address', address)
        formData.append('zipcode', zipcode)
        formData.append('city', city)
        formData.append('lan', lan)
        formData.append('country', country)
        formData.append('images[]', image)

        formData.append('id', id)
        axios({
            method: 'POST',
            url: `${process.env.REACT_APP_SERVER_LINK}/api/users/api-edit-users.php`,
            data: formData,
            headers: { 'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}` }
        }).then(function (response) {
            console.log(response.data)
            setConfirmation("Din profil har uppdaterat")
        }).catch(function (response) {
            console.log(response);
        });

    }

    const addImage = (e) => {
        const [file] = e.target.files;
        if (file) {
            setImage(e.target.files[0])
            setImageUrl(URL.createObjectURL(e.target.files[0]));
        }
    }

    return (
        <section className='profile-section'>
            <h1>Profil</h1>
            {load ?
                <div className='profile-card'>

                    <div className='profile-img'>
                        <div className='profile-img-content'>
                            <IconButton color="primary" aria-label="upload picture" component="label" onChange={addImage}>
                                <input hidden accept="image/*" type="file" />
                                <EditIcon />
                            </IconButton>
                            {image ?
                                <div className='profile-image'>
                                    <img src={imageUrl} alt="img" />
                                </div>
                                : imageLink ?
                                    <div className='profile-image'>
                                        <img src={`${process.env.REACT_APP_SERVER_LINK}/upload/${imageLink}`} alt="profile" />
                                    </div>
                                : null}
                        </div>

                    </div>

                    <div className='profile-name'>
                        <p>{dataList.fname} {dataList.lname}</p>
                    </div>
                    <div className='profile-field'>
                        <TextField value={fname} label="Förenamn" variant="outlined" onChange={(e) => setFname(e.target.value)} />
                        <TextField value={lname} label="Efternamn" variant="outlined" onChange={(e) => setLname(e.target.value)} />
                        <TextField value={email} disabled={true} label="E-post" variant="outlined" onChange={(e) => setEmail(e.target.value)} />
                        <TextField value={phone} type="number" label="Telefonnummer" variant="outlined" onChange={(e) => setPhone(e.target.value)} />
                    </div>
                    <Divider style={{ marginBottom: 24 }}><p>Address</p></Divider>
                    <div className='profile-field'>
                        <TextField value={address} label="Adress" variant="outlined" onChange={(e) => setAddress(e.target.value)} />
                        <TextField value={zipcode} label="Postnummer" variant="outlined" onChange={(e) => setZipcode(e.target.value)} />
                        <TextField value={city} label="Stad" variant="outlined" onChange={(e) => setCity(e.target.value)} />
                        <TextField value={lan} label="Län" variant="outlined" onChange={(e) => setLan(e.target.value)} />
                        <TextField value={country} label="Land" variant="outlined" onChange={(e) => setCountry(e.target.value)} />
                    </div>
                    <div>
                        <Button variant="contained" color="secondary" onClick={save}>Spara</Button>
                    </div>

                    {confirmation ? <Alert variant="filled" severity="success"> {confirmation} </Alert> : null}


                </div>
                : null}
        </section>
    )
}

export default Profile