import React from 'react'
import './style.css'

// Material
import ImageIcon from '@mui/icons-material/Image';
const UserChat = ({active}) => {
    return (
        <div className={active ? 'user-chat-container active' : 'user-chat-container'}   >
            <div className='user-chat-content'>
                <div className='user-chat-content-header'>
                    <div className='user-chat-content-header-img'>
                        <ImageIcon />
                    </div>
                    <div className='user-chat-content-header-title'>
                        <div className='user-chat-content-header-title-product'>
                            <h3>Hingst till salu</h3>
                        </div>
                        <div className='user-chat-content-header-title-email'>
                            <p>nms.com@hotmail.com</p>
                        </div>
                    </div>
                    <div className='user-chat-content-header-date'>
                        <p>Nu</p>
                    </div>
                </div>
                <div className='user-chat-content-body'>
                    <p>Vad bra! När passar dig?</p>
                </div>
            </div>
        </div>
    )
}

export default UserChat