import React, { useEffect, useState } from 'react'
import axios from 'axios';
// Material
import Button from '@mui/material/Button';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LocationOnIcon from '@mui/icons-material/LocationOn';
const ViewContactCard = ({uid}) => {

    const [userList, setUserList] = useState([])
    const [viewPhone, setViewPhone] = useState(false)

    useEffect(() => {
        loadUser()
    }, [])

    const loadUser = () => {
        axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/users/api-get-one-user.php`, {
            params: {
                id: uid
            },
            headers: {
                'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
            }
        }).then((response) => {
            setUserList(response.data[0])
        })
    }
    return (
        <div className='view-item-contact-card'>
            <h3>Kontakta annonsör</h3>

            <div className='view-item-contact-username'>
                <div className='view-item-contact-username-content'>
                    <div className='view-item-contact-username-content-icon'>
                        <AccountCircleIcon color="secondary" style={{ width: 40, height: 40 }} />
                    </div>
                    <div className='view-item-contact-username-content-name'>
                        <p>{userList.fname} {userList.lname}</p>
                        <p>{userList.user_email}</p>
                    </div>
                </div>
            </div>

            <div className='view-item-contact-user-address'>
                <div className='view-item-contact-user-address-content'>
                    <div className='view-item-contact-user-address-content-icon'>
                        <LocationOnIcon color="secondary" style={{ width: 40, height: 40 }} />
                    </div>
                    <div className='view-item-contact-user-address-content-name'>
                        <p>{userList.city} </p>
                        <p>{userList.lan} , {userList.zipcode}</p>
                    </div>
                </div>
            </div>
            {/* <div className='view-item-contact-user-map'>

            </div> */}
            <div className='view-item-contact-user-phone'>
                <Button variant="contained" fullWidth color="secondary" onClick={() => setViewPhone(!viewPhone)}>{viewPhone ? userList.phonenumber : 'Visa telefonnummer'}</Button>
            </div>
            <div className='view-item-contact-user-mail'>
            <a href={`mailto: ${userList.user_email}`}>
                <Button variant="contained" fullWidth color="secondary">Skicka meddelande</Button></a>
            </div>
        </div>
    )
}

export default ViewContactCard