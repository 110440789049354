
import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { useNavigate, useLocation } from "react-router-dom";

// Materila
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
// icons
import SearchIcon from '@mui/icons-material/Search';


const ServiceSearchTab = () => {

    const [load, setLoad] = useState(false)


    const searchFilter = useLocation().search;
    const cat = new URLSearchParams(searchFilter).get('cat');
    const inputFilter = new URLSearchParams(searchFilter).get('input');

    const serviceCat = new URLSearchParams(searchFilter).get('serviceCat');
    const typeFilter = new URLSearchParams(searchFilter).get('type');

    const navigate = useNavigate()


    const [searchType, setSearchType] = useState(0)
    const [searchInput, setSearchInput] = useState("")

    const [categoryList, setCategoryList] = useState([])
    const [category, setCategory] = useState('');
    const selectCategory = (event) => {
        setCategory(event.target.value);
    };


    useEffect(() => {
        setLoad(false)
        setCategoryList([])
        loadCategory()
        setTimeout(function () {
            if (cat === "service") {
                if (serviceCat) {
                    setCategory(serviceCat)
                }
                setSearchType(parseInt(typeFilter))
                if (searchInput) {
                    setSearchInput(inputFilter)
                }
            }
        }, 1000)
        setLoad(true)
    }, [])

    const loadCategory = () => {
        axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/category/api_get_sub_category.php`, {
            params: {
                id: 20
            },
            headers: {
                'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
            }
        }).then((response) => {
            setCategoryList(response.data)
        })
    }

    const search = () => {
        navigate(`/search?cat=service${category ? `&serviceCat=${category}` : ''}&type=${searchType}${searchInput ? `&input=${searchInput}` : ''}`)
        window.location.reload()
    }

    return (
        <div>
            {load ?
                <div>

                    <RadioGroup value={searchType} row style={{ marginBottom: 16 }} onChange={(e) => setSearchType(e.target.value)} >
                        <FormControlLabel value={0} control={<Radio />} label="Säljes" />
                        <FormControlLabel value={1} control={<Radio />} label="Sökes" />
                    </RadioGroup>

                    <FormControl variant="outlined" fullWidth style={{ marginBottom: 16 }}>
                        <InputLabel htmlFor="horse-search-title">Vad letar du efter?</InputLabel>
                        <OutlinedInput
                            label="Vad letar du efter?"
                            endAdornment={<InputAdornment position="end"><SearchIcon /></InputAdornment>}
                            onChange={(e) => setSearchInput(e.target.value)}
                        />
                        <FormHelperText>Sök</FormHelperText>
                    </FormControl>

                    <FormControl fullWidth>
                        <InputLabel >Kategori</InputLabel>
                        <Select
                            value={category}
                            label="Kategori"
                            onChange={(e) => selectCategory(e)}
                        >
                            {categoryList.map((val, i) => {
                                return <MenuItem key={i} value={val.sub_category_ID}>{val.name}</MenuItem>
                            })}
                        </Select>
                    </FormControl>

                    <Box sx={{ display: 'flex', mt: 2 }}>
                        <Box sx={{ flex: 1 }}></Box>
                        <IconButton aria-label="Sök" style={{ backgroundColor: '#0092FF', color: '#fff', width: 50, height: 50 }} onClick={search}>
                            <SearchIcon />
                        </IconButton>
                    </Box>
                </div>
                :
                <Box sx={{ width: '100%' }}>
                    <LinearProgress />
                </Box>
            }
        </div>
    )
}

export default ServiceSearchTab