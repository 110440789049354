import { useState, useEffect, useContext } from 'react'
import { HeaderContext } from '../helper/Context';
import { useNavigate, Link } from "react-router-dom";
import axios from 'axios';
import Cookies from 'universal-cookie';
// Material
import IconButton from '@mui/material/IconButton';
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
// Icons
import ChatIcon from '@mui/icons-material/Chat';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

const Login = () => {

    const navigate = useNavigate();

    const {
        setLoginDialog, loginDialog
    } = useContext(HeaderContext)

    // Authrization
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const cookies = new Cookies();
    const [errorMessage, setErrorMessage] = useState("");
    const [loading, setLoading] = useState(false)

    const toRegister = () => {
        navigate("/register");
        LoginDialogClose()
    }

    const LoginDialogClose = () => {
        setLoginDialog(false);
        setLoading(false)
    };

    const loginSubmit = async () => {
        setErrorMessage("")
        setLoading(true)
        try {
            axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/auth/login.php`, {
                params: {
                    email: email,
                    password: password
                },
                headers: {
                    'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
                }
            }).then((response) => {
                if (response.data.user) {
                    cookies.set('jwt', response.data.token, { maxAge: response.data.maxAge });
                    axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/users/api-get-one-user.php`, {
                        params: {
                            id: response.data.user
                        },
                        headers: {
                            'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
                        }
                    }).then((response) => {
                        localStorage.setItem("User", JSON.stringify({ "UUID": response.data[0].user_ID, "Name": response.data[0].fname + "" + response.data[0].lname }));
                        LoginDialogClose()
                        window.location.reload();

                    })
                } else if (response.data.error === "wrong email") {
                    setErrorMessage('Användaren hittades inte')
                    setLoading(false)
                } else if (response.data.error === "wrong password") {
                    setErrorMessage('Lösenordet matchar inte användarnamnet')
                    setLoading(false)
                } else {
                    setErrorMessage('Fel')
                    setLoading(false)
                }
            })
        }
        catch (err) {
            console.log(err)
        }
    }

    return (
        <Dialog
            open={loginDialog}
            onClose={LoginDialogClose}
        >

            <DialogTitle style={{ padding: 24, paddingBottom: 8 }}>
                {"LOGGA IN"}
            </DialogTitle>

            <DialogContent style={{ maxWidth: 374, padding: 24, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
                <form>
                    <TextField fullWidth style={{ marginBottom: 24 }} label="E-post" variant="outlined" onChange={(e) => setEmail(e.target.value)} />
                    <TextField fullWidth style={{ marginBottom: 24 }} label="Lösenord" variant="outlined" onChange={(e) => setPassword(e.target.value)} type="password" />
                    <LoadingButton
                        loading={loading}
                        type="submit"
                        onClick={loginSubmit} variant="contained" color="secondary" style={{ padding: '8px 32px' }}
                    >
                        Logga in
                    </LoadingButton>
                </form>
                <Link to="/re-password" style={{ color: '#BFBFBF', marginTop: 24 }} onClick={LoginDialogClose}>Glömt lösenordet?</Link>
                <IconButton style={{ backgroundColor: '#0092FF', position: 'absolute', top: 2, right: 10 }} onClick={toRegister}>
                    <PersonAddIcon style={{ color: '#fff' }} />
                </IconButton>
            </DialogContent>

            {errorMessage ? <Alert variant="filled" slyle={{ zIndex: 500 }} severity="error"> {errorMessage} </Alert> : null}

        </Dialog>
    )
}

export default Login