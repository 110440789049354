import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { BrowserRouter as Router } from "react-router-dom";

let theme = createTheme({
    palette: {
        primary: {
            main: '#1D7ABF',
        },
        secondary: {
            light: '#e74c49',
            main: '#0092FF',
            contrastText: '#ffffff',
        },
    },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ThemeProvider theme={theme}>
        <Router>
            <App />
        </Router>
    </ThemeProvider>
);

