import React, {useEffect} from 'react'
import '../style.css'
import { useLocation } from "react-router-dom";

// Material
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
// Tabs
import HorseSearchTap from './HorseSearchTap';
import EquipmentSearchTap from './EquipmentSearchTap';
import ServiceSearchTab from './ServiceSearchTab';
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const SearchFilterBox = () => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const searchFilter = useLocation().search;
    const cat = new URLSearchParams(searchFilter).get('cat');

    useEffect(() => {
        if(cat === "horse"){
            setValue(0)
        }else if(cat === "equipment"){
            setValue(1)
        }else if(cat === "service"){
            setValue(2)
        }
    }, [])
    

    return (
        <Box sx={{ width: '100%' }} className="search-box">
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="HÄSTAR" {...a11yProps(0)} />
                    <Tab label="UTRUSTNING" {...a11yProps(1)} />
                    <Tab label="TJÄNSTER" {...a11yProps(2)} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <HorseSearchTap /> 
            </TabPanel>
            <TabPanel value={value} index={1}>
                <EquipmentSearchTap /> 
            </TabPanel>
            <TabPanel value={value} index={2}>
                <ServiceSearchTab /> 
            </TabPanel>
        </Box>
    )
}

export default SearchFilterBox