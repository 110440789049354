import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { useNavigate, useLocation } from "react-router-dom";
// Materila
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Collapse from '@mui/material/Collapse';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import IconButton from '@mui/material/IconButton';
// icons
import SearchIcon from '@mui/icons-material/Search';

function valuetext(value) {
    return `${value}°C`;
}

const HorseSearchTap = () => {

    const navigate = useNavigate()


    const searchFilter = useLocation().search;
    const cat = new URLSearchParams(searchFilter).get('cat');
    const agefromFilter = new URLSearchParams(searchFilter).get('agefrom');
    const agetoFilter = new URLSearchParams(searchFilter).get('ageto');
    const heightfromFilter = new URLSearchParams(searchFilter).get('heightfrom');
    const heighttoFilter = new URLSearchParams(searchFilter).get('heightto');
    const pricefromFilter = new URLSearchParams(searchFilter).get('pricefrom');
    const pricetoFilter = new URLSearchParams(searchFilter).get('priceto');
    const typeFilter = new URLSearchParams(searchFilter).get('type');
    const inputFilter = new URLSearchParams(searchFilter).get('input');
    const raceFilter = new URLSearchParams(searchFilter).get('race');
    const genderFilter = new URLSearchParams(searchFilter).get('gender');
    const orientationFilter = new URLSearchParams(searchFilter).get('orientation');


    const [load, setLoad] = useState(false)

    const [searchType, setSearchType] = useState(0)
    const [searchInput, setSearchInput] = useState("")

    const [orientationList, setOrientationList] = useState([])
    const [orientation, setOrientation] = useState('');
    const selectOrientation = (event) => {
        setOrientation(event.target.value);
    };

    const [raceList, setRaceList] = useState([])
    const [race, setRace] = useState('');
    const selectRace = (event) => {
        setRace(event.target.value);
    };

    const [genderList, setGenderList] = useState([])
    const [gender, setGender] = useState('');
    const selectGender = (event) => {
        setGender(event.target.value);
    };


    const [age, setAge] = useState([0, 40]);
    const changeAge = (event, newValue) => {
        setAge(newValue);
    };

    const [manHeight, setManHeight] = useState([0, 220]);
    const changeManHeight = (event, newValue) => {
        setManHeight(newValue);
    };

    const [price, setPrice] = useState([0, 3000000]);
    const changePrice = (event, newValue) => {
        setPrice(newValue);
    };

    useEffect(() => {
        setLoad(false)
        setRaceList([])
        setOrientationList([])
        setGenderList([])
        loadOrientation()
        loadRace()
        loadGender()
        setTimeout(function () {
            if (cat === "horse") {
                setPrice([parseInt(pricefromFilter), parseInt(pricetoFilter)])
                setAge([parseInt(agefromFilter), parseInt(agetoFilter)])
                setManHeight([parseInt(heightfromFilter), parseInt(heighttoFilter)])
                if (raceFilter) {
                    setRace(raceFilter)
                }
                if (genderFilter) {
                    setGender(genderFilter)
                }
                if (orientationFilter) {
                    setOrientation(orientationFilter)
                }
                setSearchType(parseInt(typeFilter))
                if (searchInput) {
                    setSearchInput(inputFilter)
                }
            }


        }, 1000)
        setLoad(true)


    }, [])


    // Load Data functions
    const loadOrientation = () => {
        axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/category/api_get_sub_sub_category_by_cat.php`, {
            params: {
                sid: 3
            },
            headers: {
                'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
            }
        }).then((response) => {
            setOrientationList(response.data)
        })
    }



    const loadRace = () => {
        axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/category/api_get_sub_sub_category_by_cat.php`, {
            params: {
                sid: 2
            },
            headers: {
                'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
            }
        }).then((response) => {
            setRaceList(response.data)
        })
    }

    const loadGender = () => {
        axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/category/api_get_sub_sub_category_by_cat.php`, {
            params: {
                sid: 1
            },
            headers: {
                'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
            }
        }).then((response) => {
            setGenderList(response.data)
        })
    }
    // function
    const search = () => {
        navigate(`/search?cat=horse${orientation ? `&orientation=${orientation}` : ''}${race ? `&race=${race}` : ''}${gender ? `&gender=${gender}` : ''}&agefrom=${age[0]}&ageto=${age[1]}&heightfrom=${manHeight[0]}&heightto=${manHeight[1]}&pricefrom=${price[0]}&priceto=${price[1]}&type=${searchType}${searchInput ? `&input=${searchInput}` : ''}`)
        window.location.reload()
    }


    return (
        <div>
            <Collapse in={load} >

                {load ?
                    <div>
                        <RadioGroup value={searchType} row style={{ marginBottom: 16 }} onChange={(e) => setSearchType(e.target.value)} >
                            <FormControlLabel value={0} control={<Radio />} label="Säljes" />
                            <FormControlLabel value={1} control={<Radio />} label="Sökes" />
                        </RadioGroup>

                        <FormControl variant="outlined" fullWidth style={{ marginBottom: 16 }}>
                            <InputLabel htmlFor="horse-search-title">Vad letar du efter?</InputLabel>
                            <OutlinedInput
                                label="Vad letar du efter?"
                                value={searchInput}
                                endAdornment={<InputAdornment position="end"><SearchIcon /></InputAdornment>}
                                onChange={(e) => setSearchInput(e.target.value)}
                            />
                            <FormHelperText>Sök</FormHelperText>
                        </FormControl>

                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <InputLabel >Inriktning</InputLabel>
                            <Select
                                value={orientation}
                                label="Inriktning"
                                onChange={selectOrientation}
                            >
                                {orientationList.map((val, i) => {
                                    return <MenuItem key={i} value={val.sub_sub_category_ID}>{val.name}</MenuItem>
                                })}
                            </Select>
                        </FormControl>

                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <InputLabel >Ras</InputLabel>
                            <Select
                                value={race}
                                label="Ras"
                                onChange={selectRace}
                            >
                                {raceList.map((val, i) => {
                                    return <MenuItem key={i} value={val.sub_sub_category_ID}>{val.name}</MenuItem>
                                })}
                            </Select>
                        </FormControl>

                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <InputLabel >Kön</InputLabel>
                            <Select
                                value={gender}
                                label="Kön"
                                onChange={selectGender}
                            >
                                {genderList.map((val, i) => {
                                    return <MenuItem key={i} value={val.sub_sub_category_ID}>{val.name}</MenuItem>
                                })}
                            </Select>
                        </FormControl>

                        <Box sx={{ width: '100%', mb: 2 }}>
                            <p>Ålder (år)</p>
                            <Slider
                                value={age}
                                onChange={changeAge}
                                valueLabelDisplay="auto"
                                getAriaValueText={valuetext}
                                min={0}
                                max={40}
                            />
                        </Box>

                        <Box sx={{ width: '100%', mb: 2 }}>
                            <p>Mankhöjd (cm)</p>
                            <Slider
                                value={manHeight}
                                onChange={changeManHeight}
                                valueLabelDisplay="auto"
                                getAriaValueText={valuetext}
                                min={0}
                                max={220}
                            />
                        </Box>

                        <Box sx={{ width: '100%', mb: 2 }}>
                            <p>Pris (SEK)</p>
                            <Slider
                                value={price}
                                onChange={changePrice}
                                valueLabelDisplay="auto"
                                getAriaValueText={valuetext}
                                min={0}
                                max={3000000}
                            />
                        </Box>

                        <Box sx={{ display: 'flex', mt: 2 }}>
                            <Box sx={{ flex: 1 }}></Box>
                            <IconButton aria-label="Sök" style={{ backgroundColor: '#0092FF', color: '#fff', width: 50, height: 50 }} onClick={search}>
                                <SearchIcon />
                            </IconButton>
                        </Box>
                    </div>
                    :
                    null
                }

            </Collapse>

        </div>
    )
}

export default HorseSearchTap