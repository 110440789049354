import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router';
import axios from 'axios';
// Material
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';


const ChangePassword = ({ authorized }) => {

    const { vkey } = useParams()


    const navigate = useNavigate()

    useEffect(() => {
        document.title = 'Hästgruppen | Nytt konto';
    }, [authorized])

    let formData = new FormData();

    const [password, setPassword] = useState('')

    const repass = () => {

        formData.append('password', password)
        formData.append('vkey', vkey)


        axios({
            method: 'POST',
            url: `${process.env.REACT_APP_SERVER_LINK}/api/repassword/api-change-password.php`,
            data: formData,
            headers: { 'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}` }
        }).then(function (response) {
            navigate("/login")
        }).catch(function (response) {
            console.log(response);
        });
    }

    return (
        <section>
            <div className='register-container'>
                <h1>Återställ lösenord</h1>
                <div className='register-content'>
                    <TextField label="Ny lösenord" type="password" variant="outlined" fullWidth style={{ marginBottom: 24 }} onChange={(e) => setPassword(e.target.value)} />
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div></div>
                        <Button variant="contained" onClick={repass}>Spara</Button>
                    </Box>
                </div>

            </div>
        </section>
    )
}

export default ChangePassword