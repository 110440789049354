import React from 'react'
import { useNavigate } from "react-router-dom";
// Material
import Button from '@mui/material/Button';
// Icons
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
const Confirmation = () => {

    const navigate = useNavigate()

    return (
        <section>
            <h1>Bekräftelse</h1>
            <p>Din Annons har skickat till oss.</p>
            <Button
                variant="contained"
                endIcon={<NavigateNextIcon />}
                onClick={() => navigate("/account/announce")}
                style={{marginTop: 16}}
            >
                Mina Annonser
            </Button>

        </section>
    )
}

export default Confirmation