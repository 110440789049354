import React, { useState, useEffect, useContext } from 'react'
import { HeaderContext } from '../../helper/Context';
import { useLocation } from "react-router-dom";
import axios from 'axios';
import { Helmet } from 'react-helmet';

// Material
import ItemCard from '../../Components/ItemCard'
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

// Icons
import SaveAltIcon from '@mui/icons-material/SaveAlt';
// Filter
import SearchFilterBox from '../../Components/SearchBox/SearchFilterBox';
const Search = ({ id, authorized }) => {


    const {
        pageSettings
    } = useContext(HeaderContext)

    useEffect(() => {
        if (pageSettings.length > 0) {
            console.log(pageSettings.find(row => row.tag === "search").metaTags)
        }
    }, [pageSettings])

    const [disabledLinkButton, setDisabledLinkButton] = useState(false)

    const search = useLocation().search;
    const cat = new URLSearchParams(search).get('cat');
    const agefrom = new URLSearchParams(search).get('agefrom');
    const ageto = new URLSearchParams(search).get('ageto');
    const heightfrom = new URLSearchParams(search).get('heightfrom');
    const heightto = new URLSearchParams(search).get('heightto');
    const pricefrom = new URLSearchParams(search).get('pricefrom');
    const priceto = new URLSearchParams(search).get('priceto');
    const type = new URLSearchParams(search).get('type');
    const input = new URLSearchParams(search).get('input');
    const race = new URLSearchParams(search).get('race');
    const gender = new URLSearchParams(search).get('gender');
    const orientation = new URLSearchParams(search).get('orientation');
    const equipCat = new URLSearchParams(search).get('equipCat');
    const equipSubCat = new URLSearchParams(search).get('equipSubCat');
    const serviceCat = new URLSearchParams(search).get('serviceCat');



    let dateFrom = new Date();
    let dateTo = new Date()


    const [load, setLoad] = useState(false)
    const [dataList, setDataList] = useState([])

    useEffect(() => {
        loadData()
        dateFrom.setFullYear(dateFrom.getFullYear() - agefrom);
        dateTo.setFullYear(dateTo.getFullYear() - ageto);
    }, [])

    const loadData = () => {
        axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/product/api-get-product.php`, {
            headers: {
                'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
            }
        }).then((response) => {
            // if (cat === "horse") {
            //     setDataList(
            //         response.data.filter(row =>
            //             (row.price > parseInt(pricefrom) && row.price < parseInt(priceto))
            //             && (row.manHeight > parseInt(heightfrom) && row.manHeight < parseInt(heightto))
            //             && (row.age >= dateTo.getFullYear() && row.age <= dateFrom.getFullYear())
            //             && (row.cat === cat)
            //             && (row.type === type)
            //             && (input ? row.title.toLowerCase().indexOf(input.toLowerCase()) > -1 : <></>)
            //             && (race ? row.race === race : <></>)
            //             && (gender ? row.gender === gender : <></>)
            //             && (orientation ? row.targeting === orientation : <></>)
            //             && (row.status === '1' || row.status === 1)
            //         )
            //     )
            //     setLoad(true)
            // } else if (cat === "equipment") {
            //     setDataList(
            //         response.data.filter(row =>
            //             (row.cat === cat)
            //             && (input ? row.title.toLowerCase().indexOf(input.toLowerCase()) > -1 : <></>)
            //             && (parseInt(row.price) > parseInt(pricefrom) && parseInt(row.price) < parseInt(priceto))
            //             && (equipCat ? row.equip_cat === equipCat : <></>)
            //             && (equipSubCat ? row.equip_subcat === equipSubCat : <></>)
            //             && (row.status === '1' || row.status === 1)
            //         )
            //     )
            //     setLoad(true)
            // } else if (cat === "service") {
            //     setDataList(
            //         response.data.filter(row =>
            //             (row.cat === cat)
            //             && (input ? row.title.toLowerCase().indexOf(input.toLowerCase()) > -1 : <></>)
            //             && (serviceCat ? row.category === serviceCat : <></>)
            //             && (row.status === '1' || row.status === 1)
            //         )
            //     )
            //     setLoad(true)

            // }

            let filteredData;
            if (cat === "horse") {
                filteredData = filterHorseProducts(response.data);
            } else if (cat === "equipment") {
                filteredData = filterEquipmentProducts(response.data);
            } else if (cat === "service") {
                filteredData = filterServiceProducts(response.data);
            }
            setDataList(filteredData);
            setLoad(true);
        })
    }

    const filterHorseProducts = (data) => {
        return data.filter(row => {
            return (
                (row.cat === cat) &&
                (parseInt(row.price) >= parseInt(pricefrom) && parseInt(row.price) <= parseInt(priceto)) &&
                (row.hight ? parseInt(row.hight) >= parseInt(heightfrom) && parseInt(row.hight) <= parseInt(heightto) : true) &&
                // (row.age >= dateTo.getFullYear() && row.age <= dateFrom.getFullYear()) &&
                (parseInt(row.type) === parseInt(type)) &&
                (input ? row.title.toLowerCase().includes(input.toLowerCase()) : true) &&
                (race ? parseInt(row.race) === parseInt(race) : true) &&
                (gender ? row.gender === gender : true) &&
                (orientation ? row.targeting === orientation : true) &&
                (row.status === '1' || row.status === 1)
            );

        });
    }

    const filterEquipmentProducts = (data) => {
        return data.filter(row => {
            return (
                (row.cat === cat) &&
                (input ? row.title.toLowerCase().includes(input.toLowerCase()) : true) &&
                (parseInt(row.price) >= parseInt(pricefrom) && parseInt(row.price) <= parseInt(priceto)) &&
                (equipCat ? row.equip_cat === equipCat : true) &&
                (equipSubCat ? row.equip_subcat === equipSubCat : true) &&
                (row.status === '1' || row.status === 1)
            );
        });
    }

    const filterServiceProducts = (data) => {

        return data.filter(row => {
            return (
                (row.cat === cat) &&
                (input ? row.title.toLowerCase().includes(input.toLowerCase()) : true) &&
                (serviceCat ? row.category === serviceCat : true) &&
                (row.status === '1' || row.status === 1)
            );
        });
    }
    let formData = new FormData();

    const saveLink = () => {
        let fstr = window.location.href.replace(window.location.hostname, '');
        fstr = fstr.replace(window.location.protocol, '');
        fstr = fstr.replace(/\/\//g, "/");
        fstr = fstr.replace(/\//g, "");
        fstr = fstr.replace(window.location.port, '');
        fstr = fstr.replace(":", '');
        if (authorized) {
            formData.append('user_ID', id)
            formData.append('link', fstr)

            axios({
                method: 'POST',
                url: `${process.env.REACT_APP_SERVER_LINK}/api/savedlink/api-post-saved-link.php`,
                data: formData,
                headers: { 'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}` }
            }).then(function (response) {
                if (response.data === 1 || response.data === '1') {
                    setDisabledLinkButton(true)
                }

            }).catch(function (response) {
                console.log(response);
            });
        } else {
            console.log("No Auth")
        }
    }

    return (
        <section className='search-section'>
            <Helmet>
                {
                    pageSettings.length > 0 ?
                        pageSettings.find(row => row.tag === "search").metaTags
                            .map((val, i) => {
                                return (
                                    <meta key={i} name={val.name} content={val.content} />

                                )
                            })
                        : null
                }
            </Helmet>
            <div className='search-filter'>
                <SearchFilterBox />
            </div>

            <div className='search-items-content'>
                <div className='search-items-content-header'>
                    <div className='search-items-content-header-main'>
                        <h1>Sökresultat</h1>
                        <p style={{ display: 'flex', alignItems: 'center', gap: '5px' }}><span>Antal:</span> <span>{load ? dataList.length + " st" : <CircularProgress size={20} />} </span></p>
                    </div>
                    <div className='search-items-content-header-action'>
                        <Button variant="text" disabled={disabledLinkButton} endIcon={<SaveAltIcon />} onClick={saveLink}>Spara sökning</Button>
                    </div>
                </div>

                {dataList.map((val, i) => {
                    return (
                        <ItemCard
                            key={i}
                            title={val.title}
                            description={val.description}
                            price={val.price}
                            old={val.age}
                            height={val.manHeight}
                            place={val.place}
                            gender={val.gender}
                            race={val.race}
                            targeting={val.targeting}
                            id={val.product_ID}
                            uid={id}
                            authorized={authorized}
                            link={val.link_name}
                            isImg={val.image ? true : false}
                            img={`${process.env.REACT_APP_SERVER_LINK}/upload/${val.image}`}
                        />
                    )
                })}
            </div>
        </section>
    )
}

export default Search