import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import Cookies from 'universal-cookie';

// MAterial
import Backdrop from '@mui/material/Backdrop';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
// Icons
import CloseIcon from '@mui/icons-material/Close';
const CookiesBannaer = () => {

    const cookies = new Cookies();
    const maxAge = 365 * 24 * 60 * 60

    const [open, setOpen] = useState(false);
    const handleClose = () => {
        setOpen(false);
    };

    const cookiesAcception = cookies.get('cookiesAccept')

    useEffect(() => {
        if (cookiesAcception) {
            setOpen(false)
        } else {
            setOpen(true)

        }
    }, [cookiesAcception])



    const acceptCookies = (acc) => {
        handleClose()
        if (acc === "Allow") {
            setCookiesAnswer("Allow")
        } else if (acc === "Deny") {
            setCookiesAnswer("Deny")
        }
    }

    const setCookiesAnswer = (answer) => {
        cookies.set('cookiesAccept', answer, { path: '/', maxAge: maxAge });

    }


    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
        >
            <Card sx={{ maxWidth: 600, width: '100%', position: 'fixed', bottom: 16, right: 16, borderRadius: '12px' }}>
                <CardContent>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
                        <div></div>
                        <Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>
                            Hantera cookies
                        </Typography>
                        <IconButton >
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        Vi använder oss av Google analytics för att leverera så bra användarvänlighet som möjligt för våra besökare.
                    </Typography>


                </CardContent>
                <CardActions>
                    <Button size="large" variant="contained" fullWidth onClick={() => acceptCookies("Allow")}>Godkänn</Button>
                    <Button size="large" fullWidth onClick={() => acceptCookies("Deny")}>Neka</Button>
                </CardActions>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '16px', marginBottom: '16px', marginTop: '8px' }}>
                    <Link to="/terms-of-use"><p style={{ cursor: 'pointer', color: "#1e73be", textDecoration: 'underline' }}>Användarvillkor</p></Link>
                    <Link to="/personal-data-policy"><p style={{ cursor: 'pointer', color: "#1e73be", textDecoration: 'underline' }}>Personuppgiftspolicy</p></Link>
                </div>
            </Card>

        </Backdrop>
    )
}

export default CookiesBannaer