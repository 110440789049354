import React, { useEffect, useRef } from 'react'

import './style.css'
// Material
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';

import ImageIcon from '@mui/icons-material/Image';
import BlockIcon from '@mui/icons-material/Block';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
const ChatCard = () => {

    const bottomRef = useRef(null);

    useEffect(() => {
        // 👇️ scroll to bottom every time messages change
        bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, []);

    return (
        <div className='chat-card'>
            <div className='chat-card-header'>
                <div className='chat-card-header-img'>
                    <ImageIcon />
                </div>
                <div className='chat-card-header-title'>
                    <p>Hingst till salu</p>
                    <p>150 000 kr</p>
                </div>
                <div className='chat-card-header-handle'>
                    <IconButton aria-label="Block">
                        <BlockIcon />
                    </IconButton>
                    <IconButton aria-label="delete">
                        <DeleteIcon />
                    </IconButton>
                </div>
            </div>
            <div className='chat-card-body'>
                <div className='chat-card-body-receiver-container'>
                    <div className='chat-card-body-receiver-content'>
                        <p>Hej! Får man komma och kolla på den fina hästen?</p>
                    </div>
                </div>
                <div className='chat-card-body-sender-container'>
                    <div className='chat-card-body-sender-content'>
                        <p>Ja absolut!</p>
                    </div>
                </div>
                <div className='chat-card-body-receiver-container'>
                    <div className='chat-card-body-receiver-content'>
                        <p>Vad bra! När passar dig?</p>
                    </div>
                </div>
                <div className='chat-card-body-receiver-container'>
                    <div className='chat-card-body-receiver-content'>
                        <p>Hej! Får man komma och kolla på den fina hästen?</p>
                    </div>
                </div>
                <div className='chat-card-body-sender-container'>
                    <div className='chat-card-body-sender-content'>
                        <p>Ja absolut!</p>
                    </div>
                </div>
                <div className='chat-card-body-sender-container'>
                    <div className='chat-card-body-sender-content'>
                        <p>Ja absolut!</p>
                    </div>
                </div>
                <div className='chat-card-body-receiver-container'>
                    <div className='chat-card-body-receiver-content'>
                        <p>Vad bra! När passar dig?</p>
                    </div>
                </div>
                <div className='chat-card-body-sender-container'>
                    <div className='chat-card-body-sender-content'>
                        <p>Ja absolut!</p>
                    </div>
                </div>
                <div className='chat-card-body-sender-container'>
                    <div className='chat-card-body-sender-content'>
                        <p>Ja absolut!</p>
                    </div>
                </div>
                <div className='chat-card-body-receiver-container'>
                    <div className='chat-card-body-receiver-content'>
                        <p>Vad bra! När passar dig?</p>
                    </div>
                </div>
                <div className='chat-card-body-sender-container'>
                    <div className='chat-card-body-sender-content'>
                        <p>Ja absolut!</p>
                    </div>
                </div>
                <div className='chat-card-body-sender-container'>
                    <div className='chat-card-body-sender-content'>
                        <p>Ja absolut!</p>
                    </div>
                </div>
                <div ref={bottomRef} />
            </div>
            <div className='chat-card-footer'>
                <TextField fullWidth label="" variant="outlined" />
                <IconButton aria-label="Send">
                    <ArrowUpwardIcon />
                </IconButton>
            </div>
        </div>
    )
}

export default ChatCard